import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import update from 'immutability-helper';
import { Waypoint } from 'react-waypoint';
import Config from "../../static/config";
import SubNavbar from "./SubNavbar";
import { DataProviderFunc } from "./DataProvider";
import Section from "./Section";

const SectionWithRef = React.forwardRef((props, ref) => {
   return <Section innerRef={ref} {...props} />;
});

export default function NodePage({ page_name, sample_id, sample_status, content_accessor }) {
    const [visibleSections, setVisibleSections] = useState(new Set());
    const sections = Config[page_name]['Sections-'+ sample_status.status.cancer_type];
    return (
        <Container fluid={true} className='flex-column'>
            { /* only show the navbar if there's more than one thing to navigate between */
                sections && sections.length > 1 &&
                <SubNavbar page_name={page_name} sections={sections} visibleSections={visibleSections} />
            }
            <DataProviderFunc endpoint={"/submissions/?sample__id=" + sample_id + "&node_name=" + page_name} render={data=>
                <Container fluid={true} className='flex-column'>
                    {
                        sections.map((each) => {
                            const id = `${page_name}_${each}`;
                            // the topOffset and bottomOffset combo here make the 'hot area' for detecting
                            // which tab should be highlighted a narrow band directly underneath the navbar.
                            // otherwise, every visible item would be highlighted in the bar, which for lots of small
                            // items is confusing...
                            return (
                                <Waypoint key={id}
                                    topOffset="20%" bottomOffset="78%"
                                    onEnter={() => setVisibleSections(update(visibleSections, {$add: [id]}))}
                                    onLeave={() => setVisibleSections(update(visibleSections, {$remove: [id]}))}
                                >
                                    <SectionWithRef id={id}
                                        title={Config[page_name]['Content'][each]['title']}
                                        content={content_accessor({
                                            page_name: page_name, section_name: each,
                                            sample_id: sample_id, sample_status: sample_status,
                                            data: data
                                        })}
                                    />
                                </Waypoint>
                            );
                        })
                    }
                </Container>
            }/>
        </Container>
    );
}
