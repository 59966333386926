import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import API from '../../utils/api';

function TestSecure(props) {
    const [result, setResult] = useState('(not fired yet)');

    const callApi = () => {
        API.get('/secure/')
            .then((resp) => {
                console.log("Done: ", resp);
                setResult(resp.data);
            }).catch((err) => {
            setResult("Failed: " + err.message);
        });
    };

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <Button onClick={callApi}>Call Secure API</Button>
            <div style={{marginLeft: '10px', whitespace: 'pre'}}>
                { JSON.stringify(result) }
            </div>
        </div>
    )
}

export default function Debugger(props) {
    return (
        <Container style={{marginTop: 50}}>
            <h1>Debug Page</h1>
            <hr />
            <h3>Secure View (Authed Only)</h3>
            <TestSecure/>
        </Container>
    );
}
