import React from 'react';
import NodePage from "../shared/NodePage";

function get_content() {
    return <div />
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
