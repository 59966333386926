import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { apiUrl } from "../environment";
import { keycloakInstance } from "../App";

const API = axios.create({
    baseURL: apiUrl
});

const refreshAuth = (failedRequest) => {
    // let's think a bit. what circumstances can cause a request to fail?:
    //  1. we're not logged in
    //  2. our token is expired
    //  3. the fetch request started before the login process could complete
    //  4. we have a token, but for some reason this request didn't include it (related to 3)

    console.log("Login failed, reattempting; ", failedRequest);

    if (keycloakInstance.authenticated) {
        failedRequest.response.config.headers['Authentication'] = 'Bearer ' + keycloakInstance.token;
        return Promise.resolve();
    }
    else {
        // attempt to refresh it, then redirect to login if all else fails

        return new Promise((resolve, reject) => {
            // if the token will expire in less than 5 (below) seconds, it's refreshed; otherwise, this is a no-op
            // (of course, since we're here because it's invalid/expired, it will always attempt a refresh)
            keycloakInstance.updateToken(5).success(() => {
                resolve();
            }).error(() => {
                console.warn("Couldn't refresh token, redirecting to login");
                // this does a hard redirect which wipes the page state, so it doesn't
                // *really* matter what we return...
                keycloakInstance.login({
                    prompt: "Your session has expired, please log in again."
                });

                // (anyway, we'd see errors on the console in between requesting the redirect and
                // it taking effect if we didn't return anything)
                reject({message: "Login failed due to an expired token"});
            })
        });
    }
};

createAuthRefreshInterceptor(API, refreshAuth, { statusCodes: [ 401, 403 ] });

API.interceptors.request.use(request => {
    // always make sure to use the most up-to-date access token.
    // (this is especially important when using axios-auth-refresh, since it intercepts and stalls
    // requests that occur after an auth failure until the original succeeds, then allows them to proceed
    // after re-authentication has completed.)
    if (keycloakInstance.authenticated) {
        request.headers.common['Authorization'] = 'Bearer ' + keycloakInstance.token;
    }

    return request;
});


export default API;
