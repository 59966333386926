/* globals process */
// export elements from the environment here, along with possible defaults if they're not set
// (note that, for security purposes, create-react-app will only pass along NODE_ENV and env vars
//  that begin with REACT_APP_)

export const apiUrl = process.env.REACT_APP_API_URL;
export const requireAuth = process.env.REACT_APP_REQUIRE_AUTH === 'true';
export const keycloakUrl = process.env.REACT_APP_KEYCLOAK_AUTH_URL;

export const oldStaticUrl = process.env.REACT_APP_OLD_REPORTS_URL || null;
