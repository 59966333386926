import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from 'react-keycloak';
import { keycloakUrl, requireAuth } from "./environment";
import { Spinner } from 'react-bootstrap';
import API from './utils/api';

import Main from './Main';

// setup keycloak instance
// note that since we specify the config here, we don't need a /public/keycloak.json file
export const keycloakInstance = new Keycloak({
    "realm": "Tumor Profiler Reporting",
    "url": keycloakUrl,
    "clientId": "tupro-report-api"
});

const keycloakProviderInitConfig = {
    onLoad: requireAuth ? 'login-required' : 'check-sso',
    checkLoginIframe: false
    // flow: 'hybrid'
};

class App extends Component {
    onKeycloakEvent = (event, error) => {
    };

    onKeycloakTokens = (tokens) => {
        API.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;
    };

    render() {
        return (
            <KeycloakProvider
                keycloak={keycloakInstance}
                initConfig={keycloakProviderInitConfig}
                onEvent={this.onKeycloakEvent}
                onTokens={this.onKeycloakTokens}
                LoadingComponent={<Spinner animation="border" />}
            >
                <div className="App">
                    <Main />
                </div>
            </KeycloakProvider>
        );
    }
}

export default App;
