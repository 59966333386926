/**
 * Allows us to display a confirmation dialog and not block the calling thread.
 * @param msg the message to display in the confirmation box.
 * @returns {Promise<Boolean>} resolves if accepted, rejects if not
 */
export function confirmDialog(msg) {
    return new Promise(function (resolve, reject) {
        const confirmed = window.confirm(msg);
        return confirmed ? resolve(true) : reject(false);
    });
}

/**
 * Allows us to display a prompt and not block the calling thread.
 * @param message the message to display in the prompt box.
 * @returns {Promise<String>} resolves if accepted, rejects if not
 */
export function promptPromise(message) {
    return new Promise(function(resolve, reject) {
        var result = window.prompt(message);
        if (result != null) {
            resolve(result);
        } else {
            reject(new Error('User cancelled'));
        }
    });
}
