import React from 'react';
import { Nav } from "react-bootstrap";

const SubNavbar = ({page_name, sections, visibleSections}) => {
    return (
        <Nav className="sticky-top subnavbar" fill variant="pills">
            {
                sections.map((each)=> {
                    const id = `${page_name}_${each}`;

                    return (
                        <Nav.Item key={id} style={{margin: '0 3px'}}>
                            <Nav.Link active={visibleSections && visibleSections.has(id)} href={`#${id}`}>{each}</Nav.Link>
                        </Nav.Item>
                    );
                })
            }
        </Nav>
    );
};

export default SubNavbar;
