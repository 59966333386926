import React from 'react';

// var ListHead = true;
// var ListTail = true;

/*
const get_list_item = ({mystr}) => {
    if(!ListHead && mystr.startsWith('-')){
        ListHead = true;
        return (
            <ul><li>{mystr}</li>
        );
    }
    else if (ListHead && mystr.startsWith('-')){
        return false;
    }
    else if (ListHead && !mystr.startsWith('-')){
        ListHead = false;
        return ;
    }
};
*/
var get_nested_list = ({mylist, index}) => {
    var tmplist = [];
    var res = {
        "item": null,
        "index": -1
    };
    for(var i=index; i<mylist.length; i++){
        let mystr = mylist[i];
        if(mystr.trim()){
            if (mystr.startsWith('-')){
                tmplist.push(<li>{mystr.slice(1).trim()}</li>);
            }
            else {
                break
            }
        }
    }
    res["item"] = <ul>{
        tmplist
        .map((item, id) => <div key={id}>{item}</div>)
        .reduce((acc, x) => acc === null ? [x] : [acc, ' ', x], null)
    }</ul>;
    res["index"] = i;
    return res;
};

var get_list = ({mylist}) => {
    var tmplist = [];
    for(let i=0; i<mylist.length; i++){
        const mystr = mylist[i];
        if(mystr.trim()){
            let myItem;

            if(mystr.startsWith('-')){
                let res = get_nested_list({mylist: mylist.slice(0), index: i});
                myItem = res["item"];
                i = res["index"]-1;
            }
            else{
                myItem = <li>{mystr}</li>;
            }

            tmplist.push(myItem);
        }
    }
    return (
        <ul>{
            tmplist
            .map((item, id) => <div key={id}>{item}</div>)
            .reduce((acc, x) => acc === null ? [x] : [acc, ' ', x], null)
        }</ul>
    );

};


const ListContent = ({textblob}) => {

    if (textblob != null){
        return (
            <div> {get_list({mylist: textblob.split("\n")})} </div>
        );

    }
    else{
        return(
            <ul>
            </ul>
        );
    }
};

const SummaryList = ({textblob}) => {
    return (
        <ListContent textblob={textblob}/>
    );
};

export default SummaryList;
