import React, { useEffect, useState } from 'react';
import API from "../../../utils/api";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { confirmDialog, promptPromise } from "../../../utils/general";
import cogoToast from "cogo-toast";
import { useInterval } from "../../../utils/hooks";

export function MolecularSummaryReport({patient_id, sample_id, ...props}) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [creatingShot, setCreatingShot] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const refreshList = () => {
        setLoading(true);
        return API.get(`/snapshots/?sample__id=${sample_id}`)
            .then((response) => {
                setData(response.data);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    // initially refresh the list
    useEffect(() => { refreshList(); }, [])

    // continually refresh the list as long as one record is 'is_processing'
    useInterval(() => {
        refreshList();
    }, (data && data.some(x => x.is_processing)) ? 2000 : null)

    if (loading && !data) {
        return <div><Spinner /></div>;
    }

    const onSubmit = async (data) => {
        let reason = null; //

        try {
            await confirmDialog('Are you sure that you want to generate a MSR?');
            // ensure that the list is up-to-date before we determine if we should prompt the user for a reason
            await refreshList();

            if (data.length > 0) {
                reason = await promptPromise('Please supply a reason for generating this MSR');

                if (!reason || reason.length <= 0) {
                    throw new Error("Reason can't be empty");
                }
            }
        }
        catch (e) {
            // they cancelled one of the prompts, so let's bail
            cogoToast.warn(
                <div>
                    <b>Cancelled generating MSR</b>
                    { e && <div>{e.toString()}</div> }
                </div>
            );
            return;
        }

        setCreatingShot(true);
        return API.post(`/snapshots/`, { sample: sample_id, reason })
            .then(response => {
                cogoToast.success("MSR generation request submitted");
                // update local data with the saved version
                setData(data.concat(response.data));
            })
            .catch((err) => {
                if (err.response) {
                    cogoToast.error(
                        <div>
                            <b>Error occurred when generating the MSR:</b>
                            <div>{err.response.data.detail}</div>
                        </div>
                    );
                } else {
                    cogoToast.error("Unknown error occurred while saving");
                }
            })
            .finally(() => {
                setCreatingShot(false);
            })
    };

    const downloadFile = (rec) => {
        setLoadingDownload(true);

        API.get(`/snapshots/${rec.id}/download/`, { responseType: 'blob' })
            .then(({ data }) => {
                const timestamp = (new Date()).toISOString().split(".")[0].replace("T", "_");
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');

                link.href = downloadUrl;
                link.setAttribute('download', `tupro_msr_${timestamp}_report.pdf`); //any other extension

                document.body.appendChild(link);

                link.click();
                link.remove();
            })
            .catch((err) => {
                console.log(err.response);

                if (err.response) {
                    // interpret the response as text
                    const reader = new FileReader();

                    reader.onload = () => {
                        const err_data = JSON.parse(reader.result);
                        cogoToast.error(
                            <div>
                                <b>Error occurred when retrieving the MSR:</b>
                                <div>{err_data.detail}</div>
                            </div>
                        );
                    };

                    // cause onload to fire
                    reader.readAsText(err.response.data)
                }
                else {
                    cogoToast.error("Unknown error occurred while saving");
                }
            })
            .finally(() => {
                setLoadingDownload(false);
            })
    }

    return (
        <div>
            { data && (
                <div>
                    <h5>MSR History</h5>

                    <ol>
                        { data.map(rec => {
                            const lastModifyDate = data && rec.last_modifier
                                ? moment(rec.last_modifier.modified_on)
                                : null;

                            return (
                                <li key={rec.id}>
                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                        <div style={{'width': '100%'}}>
                                            MSR generated by {rec.last_modifier.full_name} at {lastModifyDate.format("h:mm A, DD.MM.YYYY")}
                                            {
                                                <div className="ml-2"><b>Reason:</b> { rec.reason || <i>initial MSR</i> }</div>
                                            }
                                            {
                                                rec.error &&
                                                <div className="ml-2">
                                                    <b>Error:</b><br />
                                                    <pre className="msr-error-report">{ rec.error }</pre>
                                                </div>
                                            }
                                        </div>

                                        <div className="ml-1">
                                            {
                                                !rec.error && (
                                                    <button
                                                        className="btn btn-outline-secondary" style={{'width': '150px'}}
                                                        onClick={() => downloadFile(rec)} disabled={rec.is_processing}
                                                    >
                                                        { !rec.is_processing
                                                            ? <span><i className="fa fa-download mr-1"/> download</span>
                                                            : <Spinner animation="border" size="sm" />
                                                        }
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </li>
                            );
                        }) }
                    </ol>
                </div>
            ) }

            <button className="btn btn-info btn-block ml-3" style={{'width': '200px'}} onClick={() => onSubmit(data)}>
            { !creatingShot ? "Generate MSR" : <Spinner animation="border" size="sm" /> }
            </button>
        </div>
    )
}
