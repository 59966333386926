import React, { useEffect, useState } from "react";
import shortid from 'shortid';
import update from 'immutability-helper';
import omitBy from 'lodash/omitBy';
import { confirmDialog } from "../../../utils/general";
import cogoToast from 'cogo-toast';
import { Button, Container, Spinner, Table } from "react-bootstrap";
import API from '../../../utils/api';

import DrugChooser from "./treatment_columns/DrugChooser";
import ApprovalChooser from "./treatment_columns/ApprovalChooser";
import NodeStatusChooser from "./treatment_columns/NodeStatusChooser";
import RnDChooser, { RndNodeModal } from "./treatment_columns/RnDChooser";
import LegendCard from "./treatment_columns/LegendCard";

export function loadTreatmentForSample(sample_id) {
    return API.get(`/treatment_entries/?sample__id=${sample_id}`)
        .then((response) => {
            return response.data.map(x => {
                return {
                    ...x,
                    drugs: x.drugs.map(z => ({label: z, value: z})),
                    _id: shortid.generate()
                }
            })
        });
}

/**
 * Base Treatments table, which implements editing rows in a treatment table.
 *
 * This component is composed within the concrete implementations Treatments and EditableTreatments; the former
 * is the standard API-backed treatments table, and the latter is a client-side editable control that invokes
 * a callback when it's saved. EditableTreatments is intended to be used in the pre-tumor board summary section,
 * where the summary writer might want to copy the main treatments table initially, but add their own tweaks.
 * @param isEditor whether the control should be editable
 * @param sample_id the ID of the sample currently being reviewed
 * @param refresh a callback, invoked with arguments (setRows, setRowsLoaded). it should populate the rows by calling
 * setRows(newRowData) and indicating that loading is done by calling setRowsLoaded(true).
 * @param saveTreatments a callback invoked with the contents of the control
 * @param showLegend whether to show the legend at the bottom of the control
 * @param trayButtons overrides  the buttons under the treatment table; by default, 'Reset' is shown
 * @return {JSX.Element}
 * @constructor
 */
function BaseTreatments({
    isEditor, sample_id, refresh, saveTreatments,
    rows, setRows, rowsLoaded, setRowsLoaded,
    showLegend = true, trayButtons = null
}) {
    // ---
    // --- centrally-collected support data
    // ---

    const [editable, setEditable] = useState([]);
    const [drugs, setDrugs] = useState([]);
    const [drugsLoaded, setDrugsLoaded] = useState(false);

    useEffect(() => {
        API.get(`/drugs/`)
            .then(response => {
                setDrugs(response.data.map(x => ({label: x.common_name, value: x.common_name})));
                setDrugsLoaded(true);
            }).catch((err) => {
            console.log("Error: " + err.message);
        });
    }, []);

    const shared = {
        drugs
    };

    // shared modal state specific to components that need a modal (i.e., ones for which 'usesModal' is true below)
    // ---

    const [editingRow, setEditingRow] = useState(undefined);

    const onCancel = () => setEditingRow(undefined);
    const onSave = (v) => {
        // tell whoever launched us that we're done
        if (editingRow._finalized)
            editingRow._finalized(v);
        setEditingRow(undefined);
    };

    // ---
    // ---  form definition, state, manipulation
    // ---
    useEffect(() => {
        refresh(setRows, setRowsLoaded);
    }, []);

    // field names, payload mappings, and optional custom components for viewing/editing
    // renders an input box/span if no custom component is specified
    const fields = [
        {name: 'Drug(s)', field: 'drug', component: DrugChooser},
        {name: 'Approval', field: 'approval', component: ApprovalChooser},
        {name: 'FMI', field: 'fmi_support', component: NodeStatusChooser},
        {name: 'fastDrug', field: 'fastdrug_support', component: NodeStatusChooser},
        {name: 'deepDrug', field: 'deepdrug_support', component: NodeStatusChooser},
        {name: 'R&D support', field: 'rd_support', component: RnDChooser, usesModal: true},
        {name: 'Targets/Evidence', field: 'target'}
    ];

    const createRow = () => {
        setRows(rows.concat({
            _id: shortid.generate(),
            drugs: [],
            approval: {},
            fmi_support: null,
            fastdrug_support: null,
            deepdrug_support: null,
            rd_support: {nodes: []},
            target: null
        }))
    };
    const updateRow = (row_id, changes) => {
        const row_idx = rows.findIndex(x => x._id === row_id);
        setRows(prevRows => update(prevRows, {[row_idx]: {$merge: changes}}));
    };
    const deleteRow = (row_id) => {
        setRows(prevRows => prevRows.filter((x) => x._id !== row_id))
    };

    // ---
    // --- the actual component
    // ---

    if (!drugsLoaded || !rowsLoaded) {
        return <Spinner animation="border" size="sm"/>;
    }

    const reallyEditable = editable && isEditor;

    return (
        <Container fluid={true}>
            <p>Summary of potential treatments based on the results of FoundationOne (FMI), fastDrugs, and deepDrugs.
                Other technologies are listed if they provide evidence that can or cannot support the respective
                treatment.</p>

            {
                isEditor && (
                    <div className="text-right d-flex align-items-center justify-content-end">
                        <label>
                            editable&nbsp;
                            <input type="checkbox" defaultChecked={isEditor} onChange={(e) => setEditable(e.target.checked)} />
                        </label>
                    </div>
                )
            }

            <Table size="sm" responsive="xl" style={{backgroundColor: 'white'}}>
                <thead>
                    <tr>
                        { // header columns...
                            fields.map(x => <th key={x.name}>{x.name}</th>)
                        }
                        { // ... plus an extra one for the 'action' column if we're editing
                            reallyEditable && <th>&nbsp;</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length > 0
                            ? rows.map((row) => {
                                return (
                                    <tr key={row._id}>
                                        { // inline editors for each column
                                            fields.map(x => {

                                                const wrapped = x.component
                                                    ? (
                                                        <x.component
                                                            editable={reallyEditable} field={x.field}
                                                            row={row} updateRow={updateRow}
                                                            shared={shared}
                                                            {...(x.usesModal && {setEditingRow})}
                                                        />
                                                    )
                                                    : (
                                                        reallyEditable
                                                            ? <input name={x.field} value={row[x.field] || ''}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRow(row._id, {[x.field]: value})
                                                                }}
                                                                style={{padding: '5px'}}
                                                                />
                                                            : <span>{row[x.field]}</span>
                                                    );

                                                return <td key={x.name}>{wrapped}</td>;
                                            })
                                        }

                                        { // this row's delete button
                                            reallyEditable && <td>
                                                <Button variant="outline-danger"
                                                    onClick={() => {
                                                        confirmDialog(`Are you sure that you wish to delete this row?`)
                                                            .then(() => {
                                                                deleteRow(row._id);
                                                            });
                                                    }}>
                                                    <i className="fas fa-trash fa-lg" />
                                                </Button>
                                            </td>
                                        }
                                    </tr>
                                );
                            })
                            : ( !reallyEditable &&
                                <tr>
                                    <td colSpan={fields.length + (reallyEditable ? 1 : 0)} className="p-2 text-center font-italic text-muted">
                                        no treatments listed
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
                <tfoot>
                    { // add new row button, in its own row
                        // (the colspan needs to take into account the 'action' column if we're editing)
                        reallyEditable && (
                            <tr>
                                <td colSpan={fields.length + (reallyEditable ? 1 : 0)} className="p-2">
                                    <Button size="sm" onClick={createRow}>
                                        <i className="fas fa-plus fa-md mr-1" />
                                        add new row
                                    </Button>
                                </td>
                            </tr>
                        )
                    }

                    <tr>
                        { // footer columns...
                            fields.map(x => <th key={x.name}>{x.name}</th>)
                        }
                        { // ... plus an extra one for the 'action' column if we're editing
                            reallyEditable && <th>&nbsp;</th>
                        }
                    </tr>
                </tfoot>
            </Table>

            { reallyEditable &&
                <div className="text-right">
                    {
                        trayButtons
                            ? trayButtons(setRows, setRowsLoaded)
                            : (
                                <>
                                    <Button className="mr-1" variant="secondary" onClick={
                                        () => {
                                            confirmDialog("Are you sure you want to replace the table with the server's copy?")
                                                .then(() => refresh())
                                        }
                                    }>Reset</Button>
                                    <Button variant="primary" onClick={() => { saveTreatments(rows) } }>Save Table</Button>
                                </>
                            )
                    }
                </div>
            }

            { showLegend && <LegendCard/> }

            { // shared modal for editing the r&d support field
                editingRow &&
                <RndNodeModal initialState={editingRow} onCancel={onCancel} onSave={onSave}/>
            }
        </Container>
    );
}

/**
 * A treatment table instance that's backed by the server. Used for editing the treatment table
 * at the bottom of the summary page.
 * @param isEditor
 * @param sample_id
 * @return {JSX.Element}
 * @constructor
 */
export default function Treatments({ isEditor, sample_id, ...props }) {
    // we store intermediate state here since it's backed by the server
    const [rowsLoaded, setRowsLoaded] = useState(true);
    const [rows, setRows] = useState([]);

    const refresh = (setRows, setRowsLoaded) => {
        return loadTreatmentForSample(sample_id).then((rows) => {
            setRows(rows);
            setRowsLoaded(true);
        })
    };

    // handler to persist data to the server
    const saveTreatments = (rows) => {
        // persists treatment table to server, clearing out previous contents
        const mappedRows = rows.map(x => {
            return omitBy({...x, drugs: x.drugs.map(x => x.label)}, (v, k) => {
                return k.startsWith('_') || !v;
            });
        });

        return API.post(`/treatment_entries/bulk_update/`, {sample_id, treatments: mappedRows})
            .then(() => {
                cogoToast.success("Saved treatment table!");
                return mappedRows;
            })
            .catch(() => {
                cogoToast.error("Failed to save treatment table");
            });
    };

    return <BaseTreatments
        isEditor={isEditor} sample_id={sample_id}
        refresh={refresh} saveTreatments={saveTreatments}
        rows={rows} setRows={setRows}
        rowsLoaded={rowsLoaded} setRowsLoaded={setRowsLoaded}
        {...props}
    />
}

/**
 * An editable treatment table that receives its contents via the 'contents' prop (rather than pulling it from the
 * server like Treatments does above), and invokes a callback with the data in it when 'saved'
 * @param isEditor
 * @param sample_id
 * @param contents the data with which to initially populate the treatment table (e.g., copied from another Treatments instance)
 * @param onSavedTreatments invoked with the mapped row data when the user clicks the 'save' button
 * @constructor
 */
export function EditableTreatments({ isEditor, sample_id, rows, setRows, onSavedTreatments, ...props }) {
    // rows are owned by our parent
    // we just store loading status here since it's never really used
    const [rowsLoaded, setRowsLoaded] = useState(true);

    const refresh = (setRows, setRowsLoaded) => {
        return new Promise((resolve) => {
            setRows(rows || []);
            setRowsLoaded(true);
            resolve();
        });
    };

    // send the mapped rows back to the caller
    const saveTreatments = (rows) => {
        // persists treatment table to server, clearing out previous contents
        const mappedRows = rows.map(x => {
            return omitBy({...x, drugs: x.drugs.map(x => x.label)}, (v, k) => {
                return k.startsWith('_') || !v;
            });
        });

        onSavedTreatments(mappedRows);
    };

    return <BaseTreatments
        isEditor={isEditor} sample_id={sample_id}
        refresh={refresh} saveTreatments={saveTreatments}
        rows={rows} setRows={setRows}
        rowsLoaded={rowsLoaded} setRowsLoaded={setRowsLoaded}
        {...props}
    />
}
