import React, { Component, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from "react-bootstrap";
import API from '../../utils/api';
import axios from "axios";
import { StatusContext } from "../Report";

class DataProvider extends Component {
    static propTypes = {
        endpoint: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired
    };
    state = {
        data: [],
        loaded: false,
        placeholder: "Loading..."
    };

    componentDidMount() {
        const {axiosInstance} = this.props;

        axiosInstance.get(this.props.endpoint)
            .then(response => {
                if (response.status !== 200) {
                    return this.setState({ placeholder: "Something went wrong" });
                }
                return response.json();
            })
            .then(data => this.setState({ data: data, loaded: true }));
    }

    render() {
        const { data, loaded, placeholder } = this.state;
        console.log('rendering data');
        return loaded ? this.props.render(data) : <p>{placeholder}</p>;
    }
}

export default DataProvider;

/**
 * Fetches from the API (with authentication, if available), rendering the 'render' prop with the fetched data when the fetch completes.
 *
 * If an error occurs, replaces the placeholder text with the error text.
 * @param endpoint the path in the API to access, e.g. /samples
 * @param render a method which receives the fetched data and produces JSX to render inside this component
 * @returns {*}
 * @constructor
 */
export function DataProviderFunc({endpoint, render}) {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [placeholder, setPlaceholder] = useState(<Spinner animation="border" variant="secondary" />);

    const callApi = useCallback(() => {
        const source = axios.CancelToken.source();

        API.get(endpoint, {cancelToken: source.token})
            .then(response => {
                setData(response.data);
                setLoaded(true);
            }).catch((err) => {
                // we should probably more gracefully handle errors here...
                console.warn("Error on request: ", err);

                if (err.response && err.response.data) {
                    const payload = err.response.data;
                    setPlaceholder("Error: " + payload.detail);
                }
                else {
                    setPlaceholder("Error: " + err.message);
                }
            });

        return source;
    }, []);

    useEffect(() => {
        const source = callApi();
        return () => { source.cancel('Cancelled outstanding request due to unmount'); };
    }, []); // we want to run when keycloak becomes ready

    // we have to pull useContext() out here since sometimes our children
    // are inconsistent about when they call useContext(). when using hooks,
    // the same hooks must be called in the same order in *every* render.
    const sampleStatus = useContext(StatusContext);

    return loaded ? render(data, { refresh: callApi, sampleStatus }) : placeholder;
}
