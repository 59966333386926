// code borrowed from the react-keycloak example:
// https://github.com/panz3r/react-keycloak/blob/master/src/demo/utils/hooks.js

import { useEffect, useRef, useState } from 'react';
import { useKeycloak } from 'react-keycloak';

/**
 * Returns the currently logged in user's roles, or null if no roles selected
 * @returns {[null, [String]]} null if uninitialized, or the roles associated with the current user
 */
export const useKeycloakRoles = () => {
    const [keycloak, initialized] = useKeycloak();
    const [roles, setRoles] = useState(null);

    useEffect(() => {
        if (initialized && keycloak.tokenParsed && keycloak.tokenParsed.realm_access) {
            setRoles(keycloak.tokenParsed.realm_access.roles);
        }
    }, [initialized]);

    return [roles, initialized];
};

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
