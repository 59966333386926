import React from 'react';
import { getendpoint } from '../shared/Utils';
import SummaryList from "../shared/SummaryList";
import StaticFigure from "../shared/StaticFigure";
import NodePage from "../shared/NodePage";

function get_content({ page_name, section_name, sample_id, data }){
    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);
        case "Overview":
            return (
                <div>
                    <StaticFigure endpoint={getendpoint("NodeDReport_Figure_vsrs.png", data)} alt_text="no data available"/>

                    <p>FIGURE LEGEND:
                    Left: Clustergram showing the intensity mean of each marker (z-normalized to control) upon drug or drug combination treatment. Right: Plot showing the fractions of cells classified into specific classes (cell subtypes, cell cycle, etc). Cell classifications are sorted according to the clustergram (for conditions) and visualized on a log2 scale. Each condition value is normalized to the control condition.

                    Cl= Cell Lines
                    Sec. AbCL= Secondary Antibody control (Cell Lines)</p>
                </div>
            );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
