import React from "react";
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default function NodeStatusChooser({editable, row, updateRow, field}) {
    const statuses = [
        {label: 'positive support', value: 'S', icon: 'fa-check-circle', color: 'green'},
	{label: 'mixed support', value: 'M', icon: 'fa-check-circle', color: 'orange'},
        {label: 'no evidence observed', value: 'N', icon: 'fa-minus-circle', color: 'grey'},
        {label: 'counter indication for treatment', value: 'C', icon: 'fa-times-circle', color: 'red'},
    ];

    const selected = statuses.find(x => row[field] === x.value);

    const titleElem = selected
        ? <i className={`fas ${selected.icon} fa-lg`} style={{color: selected.color}}/>
        : <span style={{color: 'gray', whiteSpace: 'nowrap'}}>---</span>;

    const dropdownStyle = {
        display: 'flex',
        alignItems: 'center'
    };

    if (!editable) {
        return titleElem;
    }

    return (
        <div>
            <DropdownButton variant="outline-secondary" className="node-status-chooser" title={titleElem}>
                <Dropdown.Item onSelect={(value) => updateRow(row._id, {[field]: 'NC'})}>---</Dropdown.Item>
                {
                    statuses.map((x, idx) => (
                        <Dropdown.Item key={x.label} eventKey={x.label} onSelect={() => updateRow(row._id, {[field]: x.value})} style={dropdownStyle}>
                            <i className={`fas ${x.icon} fa-md`} style={{color: x.color, marginRight: '5px'}}/>
                            {x.label}
                        </Dropdown.Item>
                    ))
                }
            </DropdownButton>
        </div>
    )
}
