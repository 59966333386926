import { useKeycloak } from "react-keycloak";
import { Container, Spinner } from "react-bootstrap";
import React from "react";

export default function UserInfo(props) {
    const [keycloak, init] = useKeycloak();
    const token = keycloak.tokenParsed;

    if (!init) {
        return <Spinner animation="border" />;
    }

    if (!token) {
        return (
            <Container style={{marginTop: 100, textAlign: 'center'}}>
                <h1>No User Info Found</h1>
                <div>Are you logged in?</div>
            </Container>
        )
    }

    return (
        <Container style={{marginTop: 50}}>
            <h1>User Information</h1>

            <div className="attribute-list">
                <div><b>Username:</b> <span>{token.preferred_username}</span></div>
                <div><b>Given Name:</b> <span>{token.given_name}</span></div>
                <div><b>Last Name:</b> <span>{token.family_name}</span></div>
                <div>
                    <b>Email:</b>
                    <span>
                        <a href={`mailto:${token.email}`}>{token.email}</a>
                        {
                            token.email_verified
                                ? <i style={{color: 'green', marginLeft: '3px'}} title="email verified" className="fa fa-check-circle" />
                                : <i style={{color: 'lightgray', marginLeft: '3px'}} title="email not verified" className="fa fa-minus-circle" />
                        }
                    </span>
                </div>

                <div><b>Valid Until:</b> <span>{new Date(token.exp*1000).toLocaleString()}</span></div>

                <div>
                    <b>Roles:</b>
                    <span>
                        <ul className="roles-list">{
                            token.realm_access && token.realm_access.roles.length > 0
                                ? token.realm_access.roles.map(x => <li key={x}>{x}</li>)
                                : <li><i>no roles assigned</i></li>
                        }</ul>
                    </span>
                </div>
            </div>

            <hr />

            <h2>Parsed JWT</h2>

            <pre>
            {JSON.stringify(token, null, 2)}
            </pre>
        </Container>
    );
}
