import React, { Component } from 'react';
import { Card } from 'react-bootstrap';


export default class Section extends Component {
    render() {
        const { title, content, id, innerRef } = this.props;

        return(
            <div ref={innerRef} className='flex-column' >
                <Card id={id} className='flex-column' style={{marginBottom: 3}}>
                    <Card.Header>{title}</Card.Header>
                    <Card.Body>{content}</Card.Body>
                </Card>
            </div>
        );
    }
}
