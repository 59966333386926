import React, { useMemo, useReducer } from 'react';
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import update, { extend } from 'immutability-helper';
import first from 'lodash/first';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import omitBy from 'lodash/omitBy';
import shortid from 'shortid';
import { confirmDialog } from "../../../../utils/general";

// immutability-helper extensions for treating arrays like sets
extend('$add_array', function(x, orig) {
    console.log("Adding ", x, " to ", orig, "...");
    return sortBy((!orig) ? [x] : orig.includes(x) ? orig : orig.concat(x));
});
extend('$remove_array', function(x, orig) {
    console.log("Removing ", x, " from ", orig, "...");
    return sortBy((!orig) ? [] : orig.filter(z => z !== x));
});

// ---
// node metadata
// ---

const available_nodes = {
    'CyTOF': { hasSubclones: true },
    'IMC': {hasSubclones: false},
    'RNA-Seq': { hasSubclones: false },
    'Proteotype': { hasSubclones: false },
    'scRNA': { hasSubclones: true },
    'scDNA': { hasSubclones: true },
    'Pathology': {hasSubclones: false},
    'CfDNA': { hasSubclones: false },
};

const subclone_count = 15;


// ---------------------------------------------------------------------------------------------------------------
// --- node display
// ---------------------------------------------------------------------------------------------------------------

const reduceClones = (clones) => {
    return clones.reduce((acc, x) => {
        const last = acc[acc.length-1];
        if (last.length === 0 || Math.abs(last[last.length-1] - x) <= 1) {
            // if we must start a run or can continue a previous one, continue it
            last.push(x);
        }
        else {
            // create a new run with us as the initiator
            acc.push([x]);
        }
        return acc;
    }, [[]]);
};

const renderClones = (clones, support) => {
    return clones && reduceClones(clones).map(run =>
        run.length > 2
            ? <span key={run}><Badge className={support}>{first(run)}</Badge>...<Badge className={support}>{last(run)}</Badge></span>
            : run.map(x => <Badge key={x} className={support}>{x}</Badge>)
    )
};

export function RnDBadge ({ _id, name, supports, tooltip, support_subclones, lacking_subclones, counter_subclones, editable, selectNode, selected }) {
    const inner = (
        <Badge
            className={`mr-1 mb-1 ${editable && 'selectable'} ${selected && 'selected'}`}
            variant={supports ? "success" : "secondary"}
            data-toggle="tooltip" title={tooltip}
            {...(editable && {onClick: () => selectNode(_id)})}
        >
            { name || <i>n/a</i>}
            { available_nodes[name] && available_nodes[name].hasSubclones && (
                <>
                    { renderClones(support_subclones, 'supports') }
                    { renderClones(lacking_subclones, 'lacks') }
                    { renderClones(counter_subclones, 'counterindicated') }
                </>
            )}
        </Badge>
    );

    return <span className="rnd-badge">
        {
            tooltip
                ? (
                    <OverlayTrigger key={name}
                        overlay={<Tooltip id={`tooltip-${name}`}>{tooltip}</Tooltip>}
                    >
                        {inner}
                    </OverlayTrigger>
                )
                : inner
        }
    </span>;
}


// ---------------------------------------------------------------------------------------------------------------
// --- node editor
// ---------------------------------------------------------------------------------------------------------------

// manages the relationship between the current nodes and candidate new records
function initNodeReducer(initialState) {
    const remaining_nodes = Object.keys(available_nodes)
        .filter(x => !initialState.nodes.map(x => x.name).includes(x));

    return {
        selected: null,
        payload: {
            nodes: initialState.nodes ? initialState.nodes.map(x => ({...x, _id: shortid.generate()})) : null
        },
        remainingNodes: remaining_nodes,
        _isDirty: false
    };
}

function nodeReducer(state, action) {
    // looks up the index of the currently-selected node, if one exists; used in multiple places
    const idx = state.selected ? state.payload.nodes.findIndex(x => x._id === state.selected) : null;

    switch (action.type) {
        case 'select_node': // args: { node_id }
            return update(state, {
               selected: {$set: action.node_id}
            });

        case 'update_candidate_field': // args: { field, value }
            if (idx === null) {
                console.warn("Update attempted with no node selected (selected: ", state.selected, ", nodes: ", state.payload.nodes, ")"); return state;
            }

            // if we change the name to a node that doesn't have subclones, we need to remove the subclone data, too
            const hasSubclones = action.field === 'name' && available_nodes[action.value].hasSubclones;

            return update(state, {
                payload: {nodes: {[idx]: {
                    [action.field]: {$set: action.value},
                    ...(!hasSubclones && {$unset: ['support_subclones', 'lacking_subclones', 'counter_subclones']})
                }}},
                _isDirty: {$set: true}
            });

        case 'update_candidate_subclone': // args: { idx, value: <'supports'/'lacking'/other> }
            if (idx === null) {
                console.warn("Update attempted with no node selected (selected: ", state.selected, ", nodes: ", state.payload.nodes, ")"); return state;
            }

            return update(state, {
                payload: {nodes: {[idx]: {
                    support_subclones: {[action.value === 'supports' ? '$add_array' : '$remove_array']: action.idx},
                    lacking_subclones: {[action.value === 'lacks' ? '$add_array' : '$remove_array']: action.idx},
                    counter_subclones: {[action.value === 'counterindicated' ? '$add_array' : '$remove_array']: action.idx}
                }}},
                _isDirty: {$set: true}
            });

        case 'add_node': { // args: none (implicitly, state.newRow)
            const existing_nodes = state.payload.nodes.map(z => z.name);
            //const remaining_nodes = Object.keys(available_nodes)
            //    .filter(x => !existing_nodes.includes(x));
            const remaining_nodes = Object.keys(available_nodes);
            if (remaining_nodes.length <= 0) {
                console.warn("Attempted to create a new node with no candidates remaining");
                return state;
            }

            const newNode = { name: remaining_nodes[0], _id: shortid.generate() };

            return update(state, {
                payload: {nodes: {$push: [newNode]}},
                selected: {$set: newNode._id},
                remainingNodes: {$set: remaining_nodes.filter(x => x !== remaining_nodes[0])},
                _isDirty: {$set: true},
            });
        }

        case 'remove_node': { // args { node_id }
            // look up the selected node so we can determine its name
            const node = state.payload.nodes[idx];

            // determine which nodes we'd have left once we remove this one
            const existing_nodes = state.payload.nodes.map(z => z.name);
            const remaining_nodes = Object.keys(available_nodes)
                .filter(x => x === node.name || !existing_nodes.includes(x));

            return update(state, {
                payload: {nodes: (orig) => orig.filter(z => z._id !== action.node_id)},
                selected: {$set: null},
                remainingNodes: {$set: remaining_nodes},
                _isDirty: {$set: true},
            });
        }

        default:
            throw new Error(`unrecognized action received in nodeReducer: ${action.type}`);
    }
}

export function RndNodeModal({ initialState, onCancel, onSave }) {
    const [state, dispatch] = useReducer(nodeReducer, initialState, initNodeReducer);

    // memoizes the selected row's valsue
    const currentRow = useMemo(() => {
        if (!state.selected)
            return null;
        return state.payload.nodes.find(x => x._id === state.selected);
    }, [state]);

    // memoize what nodes remain for selection
    const remainingNodes = useMemo(() => {
        const existing_nodes = state.payload.nodes.map(z => z.name);
        return Object.keys(available_nodes).filter(x => !existing_nodes.includes(x));
    }, [state]);

    // updates fields inside the node
    const updateRow = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;

        dispatch({ type: 'update_candidate_field', field, value });
    };

    const updateSubclones = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;
        const idx = parseInt(field.split("_")[1]);

        dispatch({ type: 'update_candidate_subclone', idx, value });
    };

    const subcloneInSet = (idx, set) => {
        return set ? set.includes(idx) : false
    };

    // adds a completed node to the list
    const addNode = () => {
        dispatch({ type: 'add_node' });
    };
    // selects a node
    const selectNode = (node_id) => {
        dispatch({ type: 'select_node', node_id });
    };
    // removes an existing node
    const removeNode = (node_id) => {
        confirmDialog(`Are you sure that you want to remove this node?`).then(() => {
            dispatch({ type: 'remove_node', node_id });
        });
    };

    const confirmedCancel = () => {
        if (state._isDirty) {
            confirmDialog('You have unsaved changes. Are you sure you want to close this dialog?').then(() => {
                onCancel()
            })
        }
        else {
            onCancel();
        }
    };

    return (
        <Modal show={true} size="lg" onHide={confirmedCancel}>
            <Modal.Header closeButton>
                <Modal.Title>R&D Support</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                    {
                        state.payload.nodes.length > 0
                            && state.payload.nodes.map((node) => (
                                <RnDBadge
                                    key={node.name} {...node}
                                    editable={true} selected={state.selected && node._id === state.selected} selectNode={selectNode}
                                />
                            ))
                    }

                    {  remainingNodes.length > 0 &&
                        <Badge className="candidate selectable" onClick={addNode}>
                            <i className="fas fa-plus fa-sm mr-1" />
                            add new node
                        </Badge>
                    }
                </div>

                <hr />

                {
                    state.selected && currentRow
                        ? (
                            <div>
                                <h4>
                                    Edit Node
                                    <span className="float-right">
                                        <Button size="sm" variant="outline-danger" onClick={() => removeNode(currentRow._id)}>
                                            <i className="fas fa-trash fa-lg" /> delete
                                        </Button>
                                    </span>
                                </h4>



                                <RnDBadge {...currentRow} />

                                <div>
                                    <label>
                                        <b>Node Name:</b><br />
                                        <select name="name" value={currentRow.name} onChange={updateRow}>
                                            <option>{currentRow.name}</option>
                                            {
                                                remainingNodes.map(x => <option key={x}>{x}</option>)
                                            }
                                        </select>
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        <b>Supports?:</b>
                                        <input name="supports" type="checkbox" checked={currentRow.supports || false} onChange={updateRow} style={{marginLeft: '5px'}} />
                                    </label>
                                </div>

                                <div>
                                    <label>
                                        <b>Tooltip:</b><br />
                                        <textarea  name="tooltip" value={currentRow.tooltip || ''} onChange={updateRow} />
                                    </label>
                                </div>

                                {
                                    available_nodes[currentRow.name].hasSubclones &&
                                    <div>
                                        <table className="subclones-tbl">
                                            <thead>
                                                <tr>
                                                    <th className="row-label">Subclones:</th>
                                                    {
                                                        Array.from({ length: subclone_count })
                                                            .map((_, idx) => <th key={idx} className="subclone-idx">{idx+1}</th>)
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="row-label">Unspecified</td>
                                                    {
                                                        Array.from({ length: subclone_count }).map((_, idx) => {
                                                            const inNoSet = (
                                                                !subcloneInSet(idx+1, currentRow.support_subclones) &&
                                                                !subcloneInSet(idx+1, currentRow.lacking_subclones) &&
                                                                !subcloneInSet(idx+1, currentRow.counter_subclones)
                                                            );

                                                            return (
                                                                <td key={idx} className="subclone-selector">
                                                                    <input name={`subclone_${idx+1}`} value="n/a" type="radio"
                                                                        checked={inNoSet}
                                                                        onChange={(e) => updateSubclones(e)}
                                                                    />
                                                                </td>
                                                            );
                                                        })
                                                    }
                                                </tr>

                                                <tr>
                                                    <td className="row-label">Supportive</td>
                                                    {
                                                        Array.from({ length: subclone_count }).map((_, idx) =>
                                                            <td key={idx} className="subclone-selector">
                                                                <input name={`subclone_${idx+1}`} value="supports" type="radio"
                                                                    checked={subcloneInSet(idx+1, currentRow.support_subclones)}
                                                                    onChange={(e) => updateSubclones(e)}
                                                                />
                                                            </td>)
                                                    }
                                                </tr>

                                                <tr>
                                                    <td className="row-label">Lacking Support</td>
                                                    {
                                                        Array.from({ length: subclone_count }).map((_, idx) =>
                                                            <td key={idx} className="subclone-selector">
                                                                <input name={`subclone_${idx+1}`} value="lacks" type="radio"
                                                                    checked={subcloneInSet(idx+1, currentRow.lacking_subclones)}
                                                                    onChange={(e) => updateSubclones(e)}
                                                                />
                                                            </td>)
                                                    }
                                                </tr>

                                                <tr>
                                                    <td>Counterindicated</td>
                                                    {
                                                        Array.from({ length: subclone_count }).map((_, idx) =>
                                                            <td key={idx} className="subclone-selector">
                                                                <input name={`subclone_${idx+1}`} value="counterindicated" type="radio"
                                                                    checked={subcloneInSet(idx+1, currentRow.counter_subclones)}
                                                                    onChange={(e) => updateSubclones(e)}
                                                                />
                                                            </td>)
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        )
                        : <div className="text-muted font-italic">select a node by clicking one above</div>
                }

                {/*<pre style={{marginTop: '1em'}}>{JSON.stringify(state, null, 2)}</pre>*/}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={confirmedCancel}>Cancel</Button>
                <Button variant="primary" onClick={() => { onSave(state.payload); }}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

// ---------------------------------------------------------------------------------------------------------------
// --- node column entity
// ---------------------------------------------------------------------------------------------------------------

export default function RnDChooser({ editable, row, updateRow, setEditingRow }) {
    // node label creation occurs in two phases:
    //  1. starting creation, which shows a popup with details about the new candidate node
    //  2. finalizing, which adds a completed candidate to the form's state

    const beginCreateNode = () => {
        setEditingRow({
            ...(row.rd_support),
            _finalized: finalizeNode
        });
    };
    const finalizeNode = (v) => {
        console.log("Got in finalizeNode: ", v);

        const mappedValue = update(v, {nodes: (nodes) => {
            return nodes.map(x => omitBy(x, (v, k) => k.startsWith('_')))
        }});

        console.log("Post-mapping: ", mappedValue);

        updateRow(row._id, {rd_support: mappedValue});
    };

    return (
        <div>
            <div>
                {
                    row.rd_support && row.rd_support.nodes && row.rd_support.nodes.map(node =>
                        <RnDBadge key={node.name} {...node} />
                    )
                }
            </div>

            {
                editable &&
                <Button variant="outline-secondary" onClick={beginCreateNode} style={{whiteSpace: 'nowrap'}}>
                edit nodes
                </Button>
            }
        </div>
    )
}
