import React from 'react';
import SummaryList from "../shared/SummaryList";
import NodePage from "../shared/NodePage";
import { Card, CardDeck, Container, Table, Button } from 'react-bootstrap';
import Iframe from 'react-iframe';

const PathoTable = ({title, data}) => (

        <Card>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                <Table striped bordered hover size="sm">
                    <tbody>
                        {data.map((each, id) => {
                            return (
                                <tr key={id}>
                                    <td>{each['label']}</td>
                                    <td>{each["val"].join()}</td>
                                </tr>
                            );
                        })
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
);
class SlideFrame extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            "sample_id": props.sample_id,
            "sample_status": props.sample_status,
            "random": 0
        };
    }

    resetIframe() {
       this.setState({random: this.state.random + 1});
   }
   
   render() {
       return (
       <Container fluid={true} className='flex-column'>
       <Button variant="outline-success" onClick={() => { this.resetIframe(); }}>Return to slide overview</Button>
           {this.state.sample_status.status.cancer_type === "M" && <Iframe key={this.state.random} url={"https://tp-patho.ethz.ch/OpenSlide/ListCaseImages/studies/melanoma/" + this.state.sample_id} width="100%" frameBorder="0" height={this.state.sample_status.status.cancer_type === "O"? "1200px":"470px"} overflow="auto"/>}
           {this.state.sample_status.status.cancer_type === "O" && <Iframe key={this.state.random} url={"https://tp-patho.ethz.ch/OpenSlide/ListCaseImages/studies/gyn/" + this.state.sample_id} width="100%" frameBorder="0" height={this.state.sample_status.status.cancer_type === "O"? "1200px":"470px"} overflow="auto"/>}
        </Container>);
   }   
}
/*
class MyFrame extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            "sample_id": props.sample_id,
            "sample_status": props.sample_status
        };
        this.frameRef = React.createRef();
        if (this.state.sample_status.status.cancer_type === "M") {
            this.state.url = "https://tp-patho.ethz.ch/OpenSlide/ListCaseImages/studies/melanoma/" + this.state.sample_id;
        }
        if (this.state.sample_status.status.cancer_type === "O") {
            this.state.url = "https://tp-patho.ethz.ch/OpenSlide/ListCaseImages/studies/gyn/" + this.state.sample_id;
        }
        this.handle_ref = (r) => {
            this.frameRef.current = r;
            alert("me")
        }
    }
    useEffect = () => {
    }
    render(){
        return(
            <Container fluid={true} className='flex-column'>
                <div>
                    <Iframe url={this.state.url}  ref={(r) => {this.handle_ref(r)}} width="100%" frameBorder="0" height={this.state.sample_status.status.cancer_type === "O"? "1200px":"470px"} overflow="auto"/>}
                    <Button onClick={() => {this.frameRef.current.contentWindow.history.go(-1)}} class="mt-3">Back</Button><Button onClick={() => {this.frameRef.current.contentWindow.history.go(-1)}} class="mt-3">Forward</Button>
                </div>
            </Container>
        )
    }
}
*/
function get_content({ page_name, section_name, sample_id, sample_status, data }){
    var patho_data = null;

    for(let i=0; i < data[0]["blobs"].length; i++){
        if( data[0]["blobs"][i]["name"] === "data.json"){
            patho_data = data[0]["blobs"][i];
        }
    }
    //                        {get_table({title: "Macroscopic report", data: patho_data["data"]["Macroscopic report"]})}

    if (!patho_data) {
        console.warn(`get_content() called for section '${section_name}', but there is no data.json blob in the server's response.`);
        return <div>data.json missing</div>;
    }

    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")?
            (
                <Container fluid={true} className='flex-column' dangerouslySetInnerHTML={{__html: data[0].summary}}>
                </Container>
            ) : (<SummaryList textblob=""/>);

        case "Slides":
        return (
            <SlideFrame sample_id={sample_id} sample_status={sample_status}/>
            );
        case "Pathology":
            console.log(patho_data);
	    let macro_tag = patho_data["data"].hasOwnProperty("Macroscopic Report")? "Macroscopic Report" : "Macroscopic report";
            let micro_tag = patho_data["data"].hasOwnProperty("Microscopic Report")? "Microscopic Report" : "Microscopic report";
            return (
                <Container fluid={true} className='flex-column'>
                    <CardDeck>
                        <PathoTable title="Macroscopic report" data={patho_data["data"][macro_tag]}/>
                        <PathoTable title="Microscopic report" data={patho_data["data"][micro_tag]}/>
                    </CardDeck>
                    <CardDeck>
                        {sample_status.status.cancer_type === "M" && <PathoTable title="Immunohistochemical Markers" data={patho_data["data"]["Immunohistochemical Markers"]}/>}
                        <PathoTable title="Diagnosis" data={patho_data["data"]["Diagnosis"]}/>
                    </CardDeck>
                </Container>
            );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
