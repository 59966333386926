import React, { Component } from 'react';
import { DataProviderFunc } from './shared/DataProvider';
import Config from '../static/config.json';
import { Link } from 'react-router-dom';
import { Container, Row, Table } from 'react-bootstrap';

import * as util from '../utils/lists';
import { oldStaticUrl } from "../environment";

function StatusButton(props){
    let style, status, avail;
    if(props.status){
        status = props.status[0];
        avail = props.status[1];
    }
    else{
        status = null;
    }
    switch (status){
        case 'complete':
            if(avail){
                style = {
                    color: 'green'
                };    
            }
            else{
                style = {
                    color: 'skyblue'
                };                
            }
            return <i className="fa fa-check-circle fa-lg" style={style} />;
        case 'complete, not available for preTB':
            style = {
                color: 'skyblue'
            };                

            return <i className="fa fa-check-circle fa-lg" style={style} />;
        case 'uploaded, incomplete':
            style = {
                color: 'gold'
            };
            return <i className="fas fa-check-circle fa-lg" style={style} />;

        case 'uploaded, issues detected':
            style = {
                color: 'orange'
            };
            return <i className="fas fa-times-circle fa-lg" style={style} />;

        case 'upload pending':
            style = {
                color: 'skyblue'
            };
            return <i className="fas fa-clock fa-lg" style={style} />;

        case 'experiment failed':
            style = {
                color: 'silver'
            };
            return <i className="fas fa-minus-circle fa-lg" style={style} />;

        case 'no sample received':
        case 'not processed for report':
        case 'not uploaded, past due': // FIXME: do we need a special icon for this one?
            style = {
                color: 'silver'
            };
            return <i className="far fa-circle fa-lg" style={style} />;

        default:
            return <div>{props.status}</div>;
    }
}

const report_status_style = {
    'complete': 'success',
    'ready': 'success',
    'ongoing': 'warning',
    'closed': 'primary',
    'not started': 'warning'
};

function TableCell(props){
    const style = {textAlign: 'center'};
    if (props.header !== 'Report'){
        if(util.include(Config["Landing"]["Table_header_nodes"], props.header)){
            return (
                <td style={style}>
                    <StatusButton status={props.data.status[Config["Landing"]["Header_to_field"][props.header]]}/>
                </td>
            );
        }
        else{
            return (
                <td style={style}>
                    {props.data.status[Config["Landing"]["Header_to_field"][props.header]]}
                </td>
            );

        }
    }
    else {
        const report_status = props.data.status[Config["Landing"]["Header_to_field"][props.header]];
        const {participant, sample_id} = props.data;
        return(
            <td>
                <Link className={`badge badge-pill badge-${report_status_style[report_status]}`}
                    to={`/report/${participant}/${sample_id}`}>
                {report_status}
                </Link>
            </td>
        );
    }
}



function LandingTable(props){
    if (props.data.length > 0){
        console.log(props.data)
        return (
            <Table hover size='sm' style={{fontSize: 13, whiteSpace: 'normal'}}>
                <thead>
                    <tr>
                        {Config["Landing"]["Table_headers"].map(
                            (each, i) => <th key={'th' + i}>{each}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(
                        (row, i) => <tr key={'tr' + i}>
                            {Config["Landing"]["Table_headers"].map(
                                (each, j) => <TableCell data={row} header={each} key={'cell_' + i + '_' + j }/>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>

        )
    }
    else {
        return <div />;
    }
}

function Desc(props){
    let style = {
        backgroundColor: '#f1f1f1'
    };
    return (
        <Container id="description" style={style} fluid={true}>
            <Container>
                <Row>
                    <div className="col-lg-12">
                        <p className="mt-3">
                            Tumor Profiler Reporting provides pathology, clinical, and scientific results from the Tumor
                            Profiler Consortium. It includes individual representation and visualisation of technologies,
                            as well as integrated analyses and clinically relevant summaries for the use in a molecular tumor board.
                        </p>

                        {
                            oldStaticUrl && (
                                <p className="mt-3 mb-3 font-italic">
                                    <i className="fas fa-exclamation-triangle mr-1" style={{color: '#ca5'}} />
                                    The previous version of the site is accessible here: <a href={oldStaticUrl}>previous reports</a>
                                </p>
                            )
                        }
                    </div>
                </Row>
            </Container>
        </Container>
    );
}

function StatusDesc(props){
    const status_array = [['complete', true], ['complete, not available for preTB', false], ['uploaded, incomplete', false], ['uploaded, issues detected', false],['upload pending', false], ['experiment failed', false], ['no sample received', false], ['not processed for report', false]];
    const style = {
        marginTop: 50
    };
    return (
        <Container style={style}>
            <h2>{props.cancerType} Cases</h2>
            <h5>Overview and status of Tumor Profiler cases</h5>
            <Table>
                <tbody>
                    <tr>
                        {
                            status_array.map((each, idx) => <td key={idx}><StatusButton status={each}/> {each}</td>)
                        }
                    </tr>
                </tbody>
            </Table>
        </Container>

    );
}

function MelanomaSection(props){
    const melanoma_phases = Config["Landing"]["MelanomaPhases"];
    return (
        <Container fluid={true}>
        <StatusDesc cancerType="Melanoma"/>

        {
            melanoma_phases.map(each =>
                <Container key={'section_' + each}>
                    <h3>{each[0]}</h3>
                    <DataProviderFunc key={each[1]}
                        endpoint={"/samples/?participant__projectPhase=" + each[1] + "&participant__cancerType=M"}
                        render={data => <LandingTable data={data} />}
                    />
                </Container>
            )
        }
        </Container>
    );
}

function OvarianSection(props){
    const ovarian_phases = Config["Landing"]["OvarianPhases"];
    return (
        <Container fluid={true}>
        <StatusDesc cancerType="Ovarian"/>

        {
            ovarian_phases.map(each =>
                <Container key={'section_' + each}>
                    <h3>{each[0]}</h3>
                    <DataProviderFunc key={each[1]}
                        endpoint={"/samples/?participant__projectPhase=" + each[1] + "&participant__cancerType=O"}
                        render={data => <LandingTable data={data} />}
                    />
                </Container>
            )
        }
        </Container>
    );
}
function AMLSection(props){
    const aml_phases = Config["Landing"]["AMLPhases"];
    return (
        <Container fluid={true}>
        <StatusDesc cancerType="AML"/>

        {
            aml_phases.map(each =>
                <Container key={'section_' + each}>
                    <h3>{each[0]}</h3>
                    <DataProviderFunc key={each[1]}
                        endpoint={"/samples/?participant__projectPhase=" + each[1] + "&participant__cancerType=D"}
                        render={data => <LandingTable data={data} />}
                    />
                </Container>
            )
        }
        </Container>
    );
}
class Landing extends Component {
    render() {
        return (
            <div>
                <Desc />
                <MelanomaSection/>
                <OvarianSection/>
                <AMLSection/>
            </div>
        );
    }
}


export default Landing;
