import React from 'react';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";

function get_content({ page_name, section_name, sample_id, data }){
    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);
        case "Example Image":
            return (
                <div>
                    <StaticFigure endpoint={getendpoint("example_image.png", data)} alt_text="no data available"/>
                </div>
            );
        case "AllMel":
            return (
                <div>
                    <StaticFigure endpoint={getendpoint("DrugResults_AllMel.png", data)} alt_text="no data available"/>
                    <p>Left: Ranked drug responses for melanoma cells. Melanoma cells are defined as expressing at least one of the markers S100B, HMB45, Sox9 or MelanA. The bar chart shows the mean relative reduction in the fraction of melanoma cells per drug. Right: Detailed view of significant drug responses. The plots show the fraction of melanoma cells (blue) and the total cell number (orange) relative to DMSO control for different concentrations of each drug.</p>
                </div>

            );
        case "AllOvCA":
            return (
                <div>
                    <StaticFigure endpoint={getendpoint("DrugResults_AllOVCA.png", data)} alt_text="no data available"/>
                    <p>Left: Ranked drug responses for ovarian cancer cells. Cancer cells are defined as expressing at least one of the markers EpCAM or E-cadherin or Pax8, and not expressing CD45. The bar chart shows the mean relative reduction in the fraction of cancer cells per drug." Right: Detailed view of significant drug responses. The plots show the fraction of cancer cells (blue) and the total cell number (orange) relative to DMSO control for different concentrations of each drug.</p>
                </div>

            );
        case "AllAML":
                return (
                    <div>
                        <StaticFigure endpoint={getendpoint("DrugResults_AllAML.png", data)} alt_text="no data available"/>
                        <p>Left: Ranked drug responses for ovarian cancer cells. Cancer cells are defined as expressing at least one of the markers EpCAM or E-cadherin or Pax8, and not expressing CD45. The bar chart shows the mean relative reduction in the fraction of cancer cells per drug." Right: Detailed view of significant drug responses. The plots show the fraction of cancer cells (blue) and the total cell number (orange) relative to DMSO control for different concentrations of each drug.</p>
                    </div>
    
                );
        case "CD34":
                    return (
                        <div>
                            <StaticFigure endpoint={getendpoint("DrugResults_CD34.png", data)} alt_text="no data available"/>                
                        </div>
        
                    );
        case "All Targets":
            return (
                <div>
                    <StaticFigure endpoint={getendpoint("DrugResultsOverview.png", data)} alt_text="no data available"/>
                    <p>Heatmap showing a summary of drug response profiles. Rows correspond to different target populations, columns to treatments. Colors indicate the mean reduction of the target population abundance relative to DMSO control. This value is calculated as (1-x/x_ctrl), where x is the fraction of target cells under treatment conditions and x_ctrl is the fraction of target cells in DMSO control. A high value thus indicates on-traget effect, zero indicates either no effect at all or non-specific killing of the whole population, and a negative value means the target population is less responsive to the drug than the rest of the population. A star(*) indicates a significant reduction in the target cell fraction (one-tailed t-test, p&#60;0.05).</p>
                </div>
            );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
