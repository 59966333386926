import React from 'react';
import PdfViewer from '../shared/PdfViewer';
import NodePage from "../shared/NodePage";

function get_content({ section_name, data }){

    switch(section_name){
        case "Report":
            // FIXME: quick fix for the demo, but this should be reviewed later
            if (data[0].datafiles.length > 0 ) {
                return (
                    data[0].datafiles && data[0].datafiles[0].file && <PdfViewer endpoint={data[0].datafiles[0].file}/>
                );
            }
            else {
                return <div>No datafiles found?</div>;
            }
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
