import React from 'react';
import { Container } from 'react-bootstrap';
import Config from '../../static/config.json';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";
import { CardDeck, Card } from 'react-bootstrap';

function get_content({ page_name, section_name, data }){
    switch (section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);

        default:
            return (
                <Container fluid={true} className="flex-column">
                    <CardDeck>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <Card>
                                <Card.Body>
                                    <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        )}

                    </CardDeck>
                </Container>

            );
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
