/**
 * Removes non-printable characters (e.g., 8203, the "zero-width space") from input.
 *
 * These characters, if present, will cause the string to test as non-empty even though
 * it appears empty.
 * @param x the input string
 * @return {*} the input with non-printable characters removed
 */
export function removeEmptyCharacters(x) {
    return x.replace(/[\u200B-\u200D\uFEFF]/g, '');
}
