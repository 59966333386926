/* globals Buffer */
import React, { useEffect, useState } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import Lightbox from "react-simple-lightbox";
import last from 'lodash/last';
import API from '../../utils/api';
import { requireAuth } from "../../environment";

const FigureFrame = ({endpoint, alt_text}) => {
    const [loading, setLoading] = useState(true);
    const [imgData, setImgData] = useState(null);

    useEffect(() => {
        if (!requireAuth || !endpoint) {
            // don't bother fetching anything if we can directly get the endpoint w/o auth
            setLoading(false);
            setImgData(endpoint);
            return;
        }

        // use our authorization to get the image, then feed it into the <img> tag
        API.get(endpoint, {responseType: 'arraybuffer'})
            .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            .then(data => {
                const extension = last(endpoint.split("."));
                setLoading(false);
                setImgData(`data:image/${extension};base64,${data}`);
            })
    }, [endpoint]);

    if (loading) {
        return <Spinner animation="border" />
    }

    return (
        <Lightbox>
            <Image src={imgData} alt={alt_text} fluid />
        </Lightbox>
    );

};

export default FigureFrame;
