import React from "react";

export default function LegendCard(props) {
    return (
        <div className="card mt-3 small">
            <div className="card-body">
                <h6 className="card-text">Legend</h6>
                <table border="0" width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <ul className="list-unstyled">
                                    <li><i className="fas fa-check-circle fa-lg" style={{color: 'green'}}/> = positive
                                        support
                                    </li>
                                    <li><i className="fas fa-chart-area fa-lg"/> = magnitude of support (tooltip
                                        indicates meaning of value)
                                    </li>
                                    <li><i className="fas fa-minus-circle fa-lg" style={{color: 'grey'}}/> = no evidence
                                        observed
                                    </li>
                                    <li><i className="fas fa-times-circle fa-lg" style={{color: 'red'}}/> = counter
                                        indication for treatment
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <ul className="list-unstyled">
                                    <li><b className="badge badge-success">CyToF</b> = support by respective R&amp;D
                                        node
                                    </li>
                                    <li><b className="badge badge-secondary">RNA-Seq</b> = no evidence observed by
                                        respective R&amp;D node
                                    </li>
                                    <li>
                                        <b className="badge supports">0</b> ... <b className="badge supports">k</b> =
                                        supportive subcloness
                                    </li>
                                    <li>
                                        <b className="badge lacks">0</b> ... <b className="badge lacks">k</b> =
                                        subclones lacking support
                                    </li>
                                    <li>
                                        <b className="badge counterindicated">0</b> ... <b className="badge counterindicated">k</b> =
                                        subclones counterindicating treatment
                                    </li>
                                    <li> - = not checked</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
