import React from 'react';
import { Card, Container } from 'react-bootstrap';
import Config from '../../static/config.json';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";

function get_cluster_profile_list(data){
    return data[0].figures
        .filter(x => x.name.includes("cluster_profile") && !x.name.includes("cluster_profile_overlapping"))
        .map(x => x.figure);
}

function get_cluster_overlapp(data){
    return data[0].figures.find(x => x.name.includes("cluster_profile_overlapping")).figure;
}

function get_content({ page_name, section_name, data }){
    if(!data[0]["blobs"]){
        return (<div>No data available</div>)
    }
    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);

        case "Cluster tree genes":
            return(
                <Container fluid={true} className='flex-column'>
                    {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                        <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                    )}
                </Container>
            );

        case "Cluster tree sorted normalised counts bins":
            return(
                <Container fluid={true} className='flex-column'>
                    {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                        <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                    )}
                </Container>
            );
        case "Cluster tree sorted cnvs bins":
            return(
                <Container fluid={true} className='flex-column'>
                    {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                        <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                    )}
                </Container>
            );

        case "Gene values in clusters":
            return(
                <Container fluid={true} className='flex-column'>
                    {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                        <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                    )}
                </Container>
            );

        case "Cluster profiles":
            if (data[0].figures.length === 0){
                return (<div />);
            }
            return(
                <Container fluid={true} className='flex-column'>
                    {
                        get_cluster_profile_list(data).map((each, index)=>
                            <Card key={index}>
                                <Card.Header>Cluster Profile {index}</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={each} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        )
                    }

                    <Card>
                        <Card.Header>Cluster profile overlapping</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={get_cluster_overlapp(data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>
                </Container>
            );

        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
