// use this tag for missing content
import React, { useEffect, useRef, useState } from "react";
import update from 'immutability-helper';
import cogoToast from 'cogo-toast';
import API from "../../../utils/api";
import { Button, Container, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { removeEmptyCharacters } from "../../../utils/strings";
import { pick } from "lodash/object";
import { confirmDialog } from "../../../utils/general";
import moment from "moment";
import EditableField from "./EditableField";
import { EditableTreatments, loadTreatmentForSample } from "./Treatments";

export function PreTumorBoardSummary({isEditor, sample_id, ...props}) {
    const [data, setData] = useState(null);
    const [editable, setEditable] = useState([]);
    const [loading, setLoading] = useState(true);

    const [treatmentRows, setTreatmentRows] = useState([]);

    // fetches current treatment table for this sample
    const reloadTreatments = () => {
        loadTreatmentForSample(sample_id).then((rows) => {
            setTreatmentRows(rows);
        });
    }

    // perform the initial population of the form
    useEffect(() => {
        API.get(`/sample_statuses/${sample_id}/`)
            .then((response) => {
                // FIXME: perhaps we should immediately convert our inputs to rich-text ones
                setData(response.data);

                console.log("Data: ", JSON.parse(JSON.stringify(response.data)));

                // if treatments is specified, set our treatment rows immediately
                if (response.data.treatments) {
                    setTreatmentRows(response.data.treatments);
                }
                else {
                    // set it from the server's current treatments table
                    reloadTreatments();
                }

                setLoading(false);
            })
    }, []);

    if (loading) {
        return <Spinner animation="border" />;
    }
    else if (!data) {
        return <div>No records exist for sample ID {sample_id}</div>
    }

    const onSubmit = async (data, status) => {
        const isSubmitting = status === 'submit';

        // if they're submitting, warn them before they do so
        // TODO: this text probably needs to be revised
        if (isSubmitting) {
            if (!await confirmDialog(
                'Are you sure that you want to submit?\n\n' +
                'Once you\'ve submitted, this report will be marked as complete.'
            )) {
                return;
            }
        }

        // retain only these fields when we push to the server
        const filteredPayload = pick(data, ['summary', 'conclusion']);
        // also store whether they hit the 'submit' or 'save' button
        filteredPayload['is_submitted'] = isSubmitting;

        // also save the treatments table and send it to the server
        filteredPayload['treatments'] = treatmentRows;

        return API.patch(`/sample_statuses/${sample_id}/`, filteredPayload)
            .then(response => {
                cogoToast.success("Summary saved!");
                // update local data with the saved version
                setData(response.data);
            })
            .catch((err) => {
                if (err.response) {
                    cogoToast.error(
                        <div>
                            <b>Error occurred when saving:</b>
                            <div>{err.response.data.detail}</div>
                        </div>
                    );
                } else {
                    cogoToast.error("Unknown error occurred while saving");
                }
            });
    };

    const updateValue = (v, field) => {
        setData(prevData => update(prevData, {[field]: {$set: v}}));
    };

    const reallyEditable = editable && isEditor;

    const lastModifyDate = data.last_modifier
      ? moment(data.last_modifier.modified_on)
      : null;

    return (
        <Container fluid={true}>
            {
                isEditor && (
                    <div className="text-right d-flex align-items-center justify-content-between">

                        <div className="font-italic text-muted">
                        {
                            data.last_modifier && (
                              <span>
                                <b>last change:</b> {data.last_modifier.last_submission_status ? 'submitted ' : 'saved '}
                                by <b>{data.last_modifier.full_name}, </b>
                                <OverlayTrigger overlay={<Tooltip id="lastmodify-tooltip">{lastModifyDate.format("h:mm A DD.MM.YYYY")}</Tooltip>}>
                                    <span>{lastModifyDate.fromNow()}</span>
                                </OverlayTrigger>
                              </span>
                            )
                        }
                        </div>
                        <label>
                            editable&nbsp;
                            <input type="checkbox" defaultChecked={isEditor} onChange={(e) => setEditable(e.target.checked)} />
                        </label>
                    </div>
                )
            }

            <EditableField name="summary"
                value={data.summary}
                updateValue={(v) => updateValue(v, 'summary')}
                editable={reallyEditable}
            />

            <div className="mt-2 mb-2 pt-2 pb-2">
                <hr />
                <EditableTreatments isEditor={isEditor} sample_id={sample_id}
                    rows={treatmentRows} setRows={setTreatmentRows}
                    trayButtons={(setRows, setRowsLoaded) => (
                        <Button className="mr-1" variant="secondary" onClick={
                            () => {
                                confirmDialog("Are you sure you want to replace the table with the treatment table version?")
                                    .then(() => { reloadTreatments(); })
                            }
                        }>Load from Treatments</Button>
                    )}
                    showLegend={false}
                />
            </div>

            {
                // hide the conclusion box if we're not editing it and it's empty
                (reallyEditable || (data.conclusion && removeEmptyCharacters(data.conclusion.trim()))) && (
                    <div className="card border-dark mt-3">
                        <h6 className="card-header border-dark bg-warning">Conclusion</h6>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-12 form-group">
                                    <div className="card-text">
                                        <EditableField name="conclusion"
                                            value={data.conclusion}
                                            updateValue={(v) => updateValue(v, 'conclusion')}
                                            rows={6} editable={reallyEditable}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            { reallyEditable && (
                <div className="col-6 offset-6 d-flex mt-3 justify-content-end pr-0">
                    <button className="btn btn-outline-primary btn-block mr-3" onClick={() => onSubmit(data, 'save')}>Save</button>
                    <button className="btn btn-primary btn-block mt-0" onClick={() => onSubmit(data, 'submit')}>Submit</button>
                </div>
            )}
        </Container>
    )
}
