import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Landing from './components/Landing';
import Report from './components/Report';
import PageHeader from "./components/shared/PageHeader";
import Debugger from "./components/pages/Debugger";
import UserInfo from "./components/pages/UserInfo";

const Main = () => (
    <BrowserRouter>
        <div>
            {/*
            paths are evaluated in order in an array, so the extraction ones must come first.
            note that '/' will match anything that contains that path, aka everything.
            */}
            <Route path={['/report/:patient_id/:sample_id', '/']} render={(props) => (
                <PageHeader  {...props.match.params} />
            )} />

            <Switch>
                <Route exact path='/' component={Landing} />
                <Route exact path='/user-info' component={UserInfo} />
                <Route exact path='/report/:patient_id/:sample_id'
                    render={(props) => <Report {...props.match.params} />}
                />

                <Route exact path='/debug' component={Debugger} />
            </Switch>
        </div>
    </BrowserRouter>
);

export default Main;
