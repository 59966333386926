import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import RichTextEditor from "react-rte-marked-unsafe";
import { removeEmptyCharacters } from "../../../utils/strings";

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ]
};

const NoContentMsg = () => (<p className="small text-secondary">Nothing submitted yet.</p>);

const EditableField = ({name, value, editable, updateValue, rows = 4}) => {
    if (!editable) {
        return value
            ? <ReactMarkdown source={value} />
            : NoContentMsg
    }

    const [richContent, setRichContent] = useState(
        value
            ? RichTextEditor.createValueFromString(value, 'markdown')
            : RichTextEditor.createEmptyValue()
    );

    const onChange = (value) => {
        setRichContent(value);
        const rendered = removeEmptyCharacters(value.toString('markdown'));
        updateValue(rendered);
    };

    return <RichTextEditor
        className="rte-summary"
        toolbarConfig={toolbarConfig}
        name={name} value={richContent}
        onChange={onChange}
    />
};

export default EditableField;
