import React from "react";
import update from 'immutability-helper';

export default function ApprovalChooser({editable, row, updateRow}) {
    const approvalChanged = (e, drug) => {
        const value = e.target.value;
        updateRow(row._id, {
            approval: update(row.approval, {$merge: {[drug]: value}})
        });
    };

    const options = [
        {label: 'approved', value: 'A'},
        {label: 'off-label', value: 'O'}
    ];

    if (!editable) {
        return <span>{
            row.drugs
                .map(x => row.approval[x.value].toLowerCase().startsWith('a') ? 'approved' : 'off-label')
                .join(" / ")
        }</span>;
    }

    return (
        row.drugs
            ? (
                row.drugs.map((x, idx) => {
                    return (
                        <span key={x.label}>
                            <select value={row.approval && row.approval[x.label]} onChange={(e) => approvalChanged(e, x.label)}>
                            {
                                options.map(opt => (
                                    <option key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </option>
                                ))
                            }
                            </select>
                            {idx < row.drugs.length - 1 && " / "}
                        </span>
                    );
                })
            )
            : <div/>
    )
}
