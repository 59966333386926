import React, {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {requireAuth} from "../../environment";
import Iframe from 'react-iframe';
import last from 'lodash/last';
import API from '../../utils/api';

const PdfViewer = ({endpoint}) => {
    const [loading, setLoading] = useState(true);
    const [pdfData, setPdfData] = useState(null);
    useEffect(()=>{
        if(!requireAuth || !endpoint){
            setLoading(false);
            setPdfData(endpoint);            
        }

        API.get(endpoint, {responseType: 'arraybuffer'})
            .then(response => Buffer.from(response.data, 'binary').toString('base64'))
            .then(data => {
                const extension = last(endpoint.split("."));
                setLoading(false);
                setPdfData(`data:application/pdf;base64,${data}`);
            })        

    }, [endpoint]);

    if(loading){
        return (<Spinner />);
    }
    return (
        <Iframe url={pdfData} width="100%" height="1000px" frameBorder="0" overflow="auto" />
    );
};

export default PdfViewer;
