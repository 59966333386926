import React from 'react';
import { Card, CardDeck, Container, CardColumns } from 'react-bootstrap';
import Config from '../../static/config.json';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";

function get_content({ page_name, section_name, data }){
    console.log(page_name);
    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);
        case "Tumor Panel":
            return (
                <Container fluid={true} className='flex-column'>
                    <Card>
                        <Card.Header>Analysis of the sample composition</Card.Header>
                        <Card.Body>
                            <CardColumns>
                            <Card>
                                <Card.Header>Tumor composition</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("all_cell_composition_SOI_T.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Cell types on t-SNE</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("all_cell_subsets_SOI_tSNE_T.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Cell types frequency vs cohort</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("all_cell_composition_SOI_vs_ref_T.png", data)} alt_text="no data available"/>
                                </Card.Body>                            
                            </Card>
                            </CardColumns>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>Tumor analysis</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={getendpoint("tumor_marker_expression_SOI_tSNE_T.png", data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Identification of tumor cell cluster using FlowSOM</Card.Header>
                        <Card.Body>
                            <CardDeck>
                            <Card>
                                <Card.Header>Tumor composition</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("tumor_cell_composition_SOI_T.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Cell types on t-SNE</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("tumor_fs_SOI_tSNE_T.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            </CardDeck>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Expression of marker per cluster compared to the cohort</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={getendpoint("tumor_marker_expression_per_cluster_vs_ref_T.png", data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>                    
                </Container>
            );

        case "Immune Panel-M":
            return (
                <Container fluid={true} className='flex-column'>
                    <Card>
                        <Card.Header>Identification of the main immune cell types present in the TME</Card.Header>
                        <Card.Body>
                            <CardColumns>
                                <Card>
                                    <Card.Header>Immune composition</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("immune_composition_SOI_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Cell types on t-SNE</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("immune_cell_subsets_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Cell types frequency vs cohort</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("immune_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>                            
                                </Card>
                            </CardColumns>                            
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>T cell frequency analysis</Card.Header>
                        <Card.Body>
                            <CardColumns>
                            <Card>
                                <Card.Header>Main T cell subsets</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("Tcell_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>CD8/CD4 ratio</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("Tcell_ratio_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>T cell subset frequencies</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("Tcell_subset_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                </Card.Body>                            
                            </Card>
                        </CardColumns>    

                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>T cell marker expression analysis</Card.Header>
                        <Card.Body>
                            <CardDeck>
                            <Card>
                                <Card.Header>T-SNE colored by T cell marker</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("Tcell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>T cell marker vs cohort</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("exCD8_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        </CardDeck>                            
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>Myeloid cell marker expression analysis</Card.Header>
                        <Card.Body>
                            <CardDeck>
                            <Card>
                                <Card.Header>T-SNE colored by marker</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("MyeloidCell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>Myeloid marker vs cohort</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("MyeloidCells_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        </CardDeck>   
                        </Card.Body>
                    </Card>
             
                </Container>
            );
            case "Immune Panel-O":
                return (
                    <Container fluid={true} className='flex-column'>
                        <Card>
                            <Card.Header>Identification of the main immune cell types present in the TME</Card.Header>
                            <Card.Body>
                                <CardColumns>
                                    <Card>
                                        <Card.Header>Immune composition</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("immune_composition_SOI_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>Cell types on t-SNE</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("immune_cell_subsets_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>Cell types frequency vs cohort</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("immune_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>                            
                                    </Card>
                                </CardColumns>                            
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>T cell frequency analysis</Card.Header>
                            <Card.Body>
                                <CardColumns>
                                <Card>
                                    <Card.Header>Main T cell subsets</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("Tcell_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>CD8/CD4 ratio</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("Tcell_ratio_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>T cell subset frequencies</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("Tcell_subset_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>                            
                                </Card>
                            </CardColumns>    
    
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>T cell marker expression analysis</Card.Header>
                            <Card.Body>
                                <CardDeck>
                                <Card>
                                    <Card.Header>T-SNE colored by T cell marker</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("Tcell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>T cell marker vs cohort</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("exCD8_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                            </CardDeck>                            
                            </Card.Body>
                        </Card>
    
                        <Card>
                            <Card.Header>Myeloid cell marker expression analysis</Card.Header>
                            <Card.Body>
                                <CardDeck>
                                <Card>
                                    <Card.Header>T-SNE colored by marker</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("MyeloidCell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Myeloid marker vs cohort</Card.Header>
                                    <Card.Body>
                                        <StaticFigure endpoint={getendpoint("MyeloidCells_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                    </Card.Body>
                                </Card>
                            </CardDeck>   
                            </Card.Body>
                        </Card>
                 
                    </Container>
                );
                case "Immune Panel-D":
                    return (
                        <Container fluid={true} className='flex-column'>
                            <Card>
                                <Card.Header>Identification of the main immune cell types present in the TME</Card.Header>
                                <Card.Body>
                                    <CardColumns>
                                        <Card>
                                            <Card.Header>Immune composition</Card.Header>
                                            <Card.Body>
                                                <StaticFigure endpoint={getendpoint("immune_composition_SOI_I.png", data)} alt_text="no data available"/>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Header>Cell types on t-SNE</Card.Header>
                                            <Card.Body>
                                                <StaticFigure endpoint={getendpoint("immune_cell_subsets_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Header>Cell types frequency vs cohort</Card.Header>
                                            <Card.Body>
                                                <StaticFigure endpoint={getendpoint("immune_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                            </Card.Body>                            
                                        </Card>
                                    </CardColumns>                            
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>T cell frequency analysis</Card.Header>
                                <Card.Body>
                                    <CardColumns>
                                    <Card>
                                        <Card.Header>Main T cell subsets</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("Tcell_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>CD8/CD4 ratio</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("Tcell_ratio_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>T cell subset frequencies</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("Tcell_subset_composition_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>                            
                                    </Card>
                                </CardColumns>    
        
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Header>T cell marker expression analysis</Card.Header>
                                <Card.Body>
                                    <CardDeck>
                                    <Card>
                                        <Card.Header>T-SNE colored by T cell marker</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("Tcell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>T cell marker vs cohort</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("actCD8_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>                            
                                </Card.Body>
                            </Card>
        
                            <Card>
                                <Card.Header>Myeloid cell marker expression analysis</Card.Header>
                                <Card.Body>
                                    <CardDeck>
                                    <Card>
                                        <Card.Header>T-SNE colored by marker</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("MyeloidCell_marker_expression_SOI_tSNE_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>Myeloid marker vs cohort</Card.Header>
                                        <Card.Body>
                                            <StaticFigure endpoint={getendpoint("Monocytes_expr_SOI_vs_ref_I.png", data)} alt_text="no data available"/>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>   
                                </Card.Body>
                            </Card>
                     
                        </Container>
                    );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
