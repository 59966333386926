import React, { useContext } from 'react';
import { Button, Card, CardDeck, CardGroup, Container, Image } from 'react-bootstrap';
import Config from '../../static/config.json';
import Section from '../shared/Section';
import { DataProviderFunc } from '../shared/DataProvider';
import { PatientSampleContext } from "../Content";
import { StatusContext } from "../Report";
import { useKeycloakRoles } from "../../utils/hooks";
import Treatments from "./partials/Treatments";
import { PreTumorBoardSummary } from "./partials/PreTumorBoardSummary";
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import DOPIBOJ_baseline_diagnostics from '../../static/data/DOPIBOJ/DOPIBOJ_baseline_diagnostics.png';
import DOPIBOJ_history from '../../static/data/DOPIBOJ/DOPIBOJ_history.png';
import DOPIBOJ_pathogene from '../../static/data/DOPIBOJ/DOPIBOJ_pathogene.png';
import DOPIBOJ_figure from '../../static/data/DOPIBOJ/DOPIBOJ_figure.png';
import DEJIBEB_baseline from '../../static/data/DEJIBEB/DEJIBEB_baseline.png';
import DEJIBEB_history from '../../static/data/DEJIBEB/DEJIBEB_history.png';
import DEJIBEB_patho from '../../static/data/DEJIBEB/DEJIBEB_patho.png';
import DADEDEM_baseline from '../../static/data/DADEDEM/DADEDEM_baseline.png';
import DADEDEM_history from '../../static/data/DADEDEM/DADEDEM_history.png';
import DADEDEM_patho from '../../static/data/DADEDEM/DADEDEM_patho.png';
import DUTUDIF_baseline_diagnostics from '../../static/data/DUTUDIF/DUTUDIF_baseline_diagnostics.png';
import DUTUDIF_history from '../../static/data/DUTUDIF/DUTUDIF_history.png';
import DUTUDIF_pathogene from '../../static/data/DUTUDIF/DUTUDIF_pathogene.png';
import DUTUDIF_figure from '../../static/data/DUTUDIF/DUTUDIF_figure.png';
import DYWYJUB_history from '../../static/data/DYWYJUB/DYWYJUB_history.png';
import DYWYJUB_FMI from '../../static/data/DYWYJUB/DYWYJUB_FMI.png';
import DEGAFIJ_history from '../../static/data/DEGAFIJ/DEGAFIJ_history.png';
import DEGAFIJ_FMI from '../../static/data/DEGAFIJ/DEGAFIJ_FMI.png';
import DEHAFEH_pathogene from '../../static/data/DEHAFEH/DEHAFEH_pathogene.png';
import DIBAHUC_history from '../../static/data/DIBAHUC/DIBAHUC_history.png';
import DYBIDYF_history from '../../static/data/DYBIDYF/DYBIDYF_history.png';
import Lightbox from "react-simple-lightbox";
import { MolecularSummaryReport } from "./partials/MolecularSummaryReport";

//FIX ME: tmp solution to add aml info on summary page
function AMLPatientInfo(props){
    return (
    <Container>
        {props.sample === 'DOPIBOJ' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DOPIBOJ_baseline_diagnostics} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DOPIBOJ_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DOPIBOJ_pathogene} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DOPIBOJ_figure} fluid/>
                            </Lightbox>

                        </Card.Body>
                    </Card>

                </CardDeck>

            </Container>}
        {props.sample === 'DUTUDIF' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DUTUDIF_baseline_diagnostics} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DUTUDIF_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DUTUDIF_pathogene} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DUTUDIF_figure} fluid/>
                            </Lightbox>

                        </Card.Body>
                    </Card>

                </CardDeck>
            </Container>}
            {props.sample === 'DEJIBEB' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEJIBEB_baseline} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEJIBEB_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEJIBEB_patho} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DADEDEM' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DADEDEM_baseline} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DADEDEM_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DADEDEM_patho} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DEHAFEH' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEHAFEH_pathogene} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DEGAFIJ' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEGAFIJ_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DEGAFIJ_FMI} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DYWYJUB' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DYWYJUB_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DYWYJUB_FMI} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DIBAHUC' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DIBAHUC_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
            {props.sample === 'DYBIDYF' &&
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Lightbox>
                                <Image src={DYBIDYF_history} fluid/>
                            </Lightbox>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>}
    </Container>);
}
function Patient(props){
    let cancer_type = "";
    if(props.ptc.sample_id.startsWith('M')){
        cancer_type = 'melanoma';
    }
    else if (props.ptc.sample_id.startsWith('O') || (props.ptc.sample_id.startsWith('A'))){
        cancer_type = "ovarian";
    }
    else if (props.ptc.sample_id.startsWith('D') || (props.ptc.sample_id.startsWith('U'))) {
        cancer_type = "aml";
    }
    if (props.data.length > 0){
        return (
            <Container>
                <ul>
                    {Config['Summary']['Content']['Patient']['list_item-'+cancer_type].map((each)=>
                        <li key={each[1]}>
                            <b>{each[0]}</b>: {props.data[0][each[1]]}
                        </li>
                    )}
                </ul>
                {props.ptc.sample_id.startsWith('D') && <AMLPatientInfo sample={props.ptc.sample_id}/>}
            </Container>
        );
    }
    else {
        return <p />
    }
}


function HypoxiaBlock(props){
    return (
        <Card className="text-center">
            <Card.Header>Hypoxia score</Card.Header>
            <Card.Body>
                <Button href="#" className="mt-3" variant="secondary">No Data</Button>

            </Card.Body>

        </Card>
    );
}

function CytofBlock(props){
    if(props["status"]["cytof"][0] === "complete"){
        return <DataProviderFunc endpoint={"/submissions/?sample__id=" + props["sample_id"] + "&node_name=CyTOF"} render={data=>{
            return (
                <Card className="text-center">
                    <Card.Header>CyTOF</Card.Header>
                    <Card.Body>
                        <Card>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint("immune_composition_SOI_I.png", data)} alt_text="no data available"/>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint("tumor_composition_SOI_T.png", data)} alt_text="no data available"/>
                            </Card.Body>
                        </Card>
                        <Card style={{marginBottom: '1em'}}>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint("all_cell_composition_SOI_T.png", data)} alt_text="no data available"/>
                            </Card.Body>
                        </Card>
                        <Button onClick={()=>props.handle_tab("CyTOF")} className="mt-3">Go to CyTOF</Button>
                    </Card.Body>
                </Card>
            );
        }}/>
    }
    else {
        return (
            <Card>
                <Card.Header className="text-center">CyTOF</Card.Header>
                <Card.Body><Button className="mt-3">No data</Button></Card.Body>
            </Card>
        )
    }
}

function ScRNABlock(props){
    if(props["status"]["scrna"][0] === "complete"){
        return (
            <DataProviderFunc endpoint={"/submissions/?sample__id=" + props["sample_id"] + "&node_name=ScRNA"} render={data=>{
                return (
                    <Card className="text-center">
                    <Card.Header>ScRNA</Card.Header>
                        <Card.Body>
                            <Card style={{marginBottom: '1em'}}>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint("celltype_barplot.png", data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                            <Button onClick={()=>props.handle_tab("ScRNA")} className="mt-3">Go to ScRNA</Button>
                        </Card.Body>
                    </Card>

                );

            }}/>
        );
    }
    else {
        return (
            <Card className="text-center">
                <Card.Header>ScRNA</Card.Header>
                <Card.Body><Button className="mt-3">No data</Button></Card.Body>
            </Card>
        )
    }
}

function FMI_format_cell({type, vals}){
    //console.log(vals);
    switch(type){
        case "Gene alterations":
            let res_gene = vals["GENE"];
            let res_gene_info = "";
            let res_vaf = "";
            let res_exon = "";
            let res_cn = "";
            if (vals.hasOwnProperty("SV-PROTEIN-CHANGE")){
                res_gene_info = vals["SV-PROTEIN-CHANGE"];
            }
            if (vals.hasOwnProperty("SV-PERCENT-READS")){
                res_vaf = "VAF " + vals["SV-PERCENT-READS"] + "%";
            }
            if (vals.hasOwnProperty("CNA-EXONS")){
                res_exon = vals["CNA-EXONS"] + " exons";
            }
            if (vals.hasOwnProperty("CNA-COPY-NUMBER")){
                res_cn = "CN " + vals["CNA-COPY-NUMBER"];
            }

            return (
                <span><b>{res_gene}</b>{res_gene_info&&<span>{" " + res_gene_info + " "}</span>}{res_vaf&&<span>{"(" + res_vaf+ ")"} </span>}{res_exon&&<span>{"(" + res_exon + " " + res_cn + ")"}</span>}</span>
            );
        case "Additional findings":
            let res_arr = vals.split('-');
            console.log(res_arr);
            if(res_arr.length >= 2) {
                return (
                    <span><b>{res_arr[0].trim() + ": "}</b><span>{res_arr.slice(1).join(" - ").trim()}</span></span>
                );
            }
            else {
                return(<span />);
            }
        case "Disease relevant genes with no reportable alterations":
            return (<span><b>{vals}</b></span>);
        default:
            return <span />;
    }
}

function FMIBlock(props){
    if (props["status"]["fmi"][0] === "complete" && props["status"]["fmi"][1] == true){
        return (
            <DataProviderFunc endpoint={"/submissions/?sample__id=" + props["sample_id"] + "&node_name=FMI"} render={data=>{
                var fmi_data = null;
                for(let i=0; i < data[0]["blobs"].length; i++){
                    if( data[0]["blobs"][i]["name"] === "info_for_summary.json"){
                        fmi_data = data[0]["blobs"][i];
                    }
                }
                //console.log(fmi_data["data"]);

                return (

                    <Card>
                        <Card.Header>Genomic summary (FMI)</Card.Header>
                        <Card.Body>
                            <CardDeck>
                                <Card>
                                    <Card.Header>Gene alternations</Card.Header>
                                    <Card.Body>
                                        <ul>
                                            {
                                                fmi_data["data"].hasOwnProperty("Gene alterations")?
                                                    fmi_data["data"]["Gene alterations"].map((each, i)=>(
                                                        <li key={"gene_alternation_" + i}>{FMI_format_cell({"type": "Gene alterations", "vals": each})}</li>
                                                    ))
                                                :
                                                    <li/>
                                            }
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Additional findings</Card.Header>
                                    <Card.Body>
                                        <ul>
                                            {
                                                fmi_data["data"].hasOwnProperty("Additional findings")?
                                                    fmi_data["data"]["Additional findings"].map((each, i)=>(
                                                        <li key={"additional_finding_" + i}>{FMI_format_cell({"type": "Additional findings", "vals":each})}</li>
                                                    ))
                                                :
                                                    <li/>
                                            }
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>Disease relevant genes with no reportable alterations</Card.Header>
                                    <Card.Body>
                                        <ul>
                                            {
                                                fmi_data["data"].hasOwnProperty("Disease relevant genes with no reportable alterations")?
                                                    fmi_data["data"]["Disease relevant genes with no reportable alterations"].map((each, i)=>(
                                                        <li key={"relevant_gene_" + i}>{FMI_format_cell({"type": "Disease relevant genes with no reportable alterations", "vals": each})}</li>
                                                    ))
                                                :
                                                    <li/>
                                            }
                                        </ul>
                                    </Card.Body>
                                </Card>

                            </CardDeck>
                        </Card.Body>
                    </Card>
                );
            }
            }/>
        )
    }
    else{
        return (
            <Card>
            <Card.Header>Genomic summary (FMI)</Card.Header>
            <Card.Body>No data</Card.Body>
            </Card>
        )
    }
}
function MolecularPhenotype(props){
    return(
        <Card>
        <Card.Header>Molecular Phenotype</Card.Header>
        <Card.Body>
            {props.tumor_type!="D" &&<FMIBlock {...props}/>}
            <CardGroup>
                <HypoxiaBlock {...props}/>
                <CytofBlock {...props}/>
                <ScRNABlock {...props}/>
            </CardGroup>
        </Card.Body>
        </Card>
    );
}


function HistologicalPhenotype(props){
    if (props["status"]["pathology"][0] === "complete" && props["status"]["pathology"][1] == true){
        return(
            <DataProviderFunc endpoint={"/submissions/?sample__id=" + props["sample_id"] + "&node_name=Pathology"} render={data=>{
                var patho_data = null;
                for(let i=0; i < data[0]["blobs"].length; i++){
                    if( data[0]["blobs"][i]["name"] === "info_for_summary.json"){
                        patho_data = data[0]["blobs"][i];
                    }
                }
                return (
                    <Card>
                    <Card.Header>Histological Phenotype</Card.Header>
                    <Card.Body>
                        <Card>
                            <Card.Header>Immunodiagnosis</Card.Header>
                            <Card.Body>
                                {
                                    patho_data["data"].hasOwnProperty("Microscopic report")?
                                        <p>
                                            { patho_data["data"]["Microscopic report"].hasOwnProperty("Immunodiagnosis")? patho_data["data"]["Microscopic report"]["Immunodiagnosis"]: "; TLS: "
                                        + patho_data["data"]["Microscopic report"].hasOwnProperty("Tertiary lymphoid structures (TLS)")?patho_data["data"]["Microscopic report"]["Tertiary lymphoid structures (TLS)"]:""}
                                        </p>
                                    :
                                        <p />
                                }
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>Immunohistochemistry</Card.Header>
                            <Card.Body>{
                                patho_data["data"].hasOwnProperty("Immunohistochemical Markers")?
                                    <div>
                                        <ul>
                                            <li>{"PD1: " + patho_data["data"]["Immunohistochemical Markers"]["PD1"]}</li>
                                            <li>{"PDL1: " + patho_data["data"]["Immunohistochemical Markers"]["PD-L1 (clone SP263)"]}</li>
                                            <li>{"Tumor Cell Portion: " + patho_data["data"]["Microscopic report"]["Tumor Cell Portion"]}</li>
                                        </ul>
                                        <ul>
                                            <li>{"CD3: " + patho_data["data"]["Immunohistochemical Markers"]["CD3"]}</li>
                                            <li>{"CD4: " + patho_data["data"]["Immunohistochemical Markers"]["CD4"]}</li>
                                            <li>{"CD8: " + patho_data["data"]["Immunohistochemical Markers"]["CD8"]}</li>
                                            <li>{"CD20: " + patho_data["data"]["Immunohistochemical Markers"]["CD20"]}</li>
                                            <li>{"FoxP3: " + patho_data["data"]["Immunohistochemical Markers"]["FoxP3"]}</li>
                                        </ul>
                                        </div>

                                :
                                    <p />
                            }
                            </Card.Body>
                        </Card>
                    </Card.Body>
                </Card>
                );
            }
            }/>

        );
    }
    else{
        return (
            <Card>
            <Card.Header>Pathology Summary</Card.Header>
            <Card.Body>No data</Card.Body>
            </Card>
        )
    }
}
function Phenotype(props){
    return(
        <Container fluid={true} className='flex-column'>
            {
                props.status.cancer_type === "M" && <HistologicalPhenotype {...props}/>
            }
             <MolecularPhenotype {...props}/>

        </Container>


    );
}


function get_content(section_name, ptc, sampleStatus, isEditor, handle_tab){

    if(section_name === 'Patient'){
        let endpoint = null;

        if(sampleStatus.status.cancer_type === "M"){
            endpoint = "/clinicalMelanoma/?participant__id=" + ptc.patient_id;
        }
        if(sampleStatus.status.cancer_type === "O"){
            endpoint = "/clinicalOvarian/?participant__id=" + ptc.patient_id;
        }
        if(sampleStatus.status.cancer_type === "D"){
            endpoint = "/clinicalAML/?participant__id=" + ptc.patient_id;
        }
        return <DataProviderFunc key="clinical_info"
            endpoint={endpoint}
            render={data => <Patient data={data} ptc={ptc}/>}
        />;
    }

    else if(section_name === 'Summary'){
        // //FIX ME: fix the preTB summary table later
        // return <div/>
        // //return ;

        return <PreTumorBoardSummary {...ptc} isEditor={isEditor} />;
    }
    else if (section_name === 'MSR') {
        return <MolecularSummaryReport patient_id={ptc.patient_id} sample_id={ptc.sample_id}/>
    }
    else if(section_name === 'Phenotype'){
        let data = {
            "patient_id": ptc.patient_id,
            "sample_id": ptc.sample_id,
            "status": sampleStatus.status,
            "tumor_type": sampleStatus.status.cancer_type,
        };

        return <Phenotype {...data} handle_tab={handle_tab}/>;
    }
    else if(section_name === 'Treatments'){
        return <Treatments {...ptc} isEditor={isEditor} />;
    }
    else {
        console.warn(`Unkown section name '${section_name}' provided to get_content()`);
        return <div />;
    }
}

export default function Summary({ page_name, sample_id, handle_tab}) {
    // determine if we're an editor
    const [roles] = useKeycloakRoles();
    // 'ptc' is a context provider from Content that contains patient_id and sample_id
    const ptc = useContext(PatientSampleContext);
    const sampleStatus = useContext(StatusContext);

    const isEditor = roles && roles.includes('editor');

    return (
        <Container fluid={true} className='flex-column' style={{marginBottom: '3em'}}>
            {
                Config[page_name]['Sections']
                    .filter((each) =>
                        !Config[page_name]['Content'][each].editorOnly || isEditor
                    )
                    .map((each)=>
                        <Section
                            title={Config[page_name]['Content'][each]['title']} id={page_name + '_' + each}
                            content={get_content(each, ptc, sampleStatus, isEditor, handle_tab)} key={page_name + '_' + each}
                        />
                    )
            }
        </Container>
    );
}
