import React from 'react';
import { Card, CardGroup, Container, CardColumns, CardDeck } from 'react-bootstrap';
import Config from '../../static/config.json';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";
import Iframe from 'react-iframe';

var colorlist = ["red", "orange", "yellow", "green", "blue", "purple"];

function get_content({ page_name, section_name, sample_status, data }){
    var figurelist = {};
    var namelist = [];
    if(!data[0]["blobs"] || data[0]["blobs"].length==0){
        return (<div>No data available</div>)
    }

    for(let i=0; i<data[0]["figures"].length; i++){
        for(let j=0; j<colorlist.length; j++){
            if (data[0]["figures"][i]["name"].indexOf("-" + colorlist[j]) !== -1){

                let indexHead = data[0]["figures"][i]["name"].indexOf('_')+1;
                let indexTail = data[0]["figures"][i]["name"].indexOf('.png');
                let name = data[0]["figures"][i]["name"].slice(indexHead, indexTail);

                if (!figurelist.hasOwnProperty(name)){
                    let namearr = name.split('_');
                    let caption = "";
                    for(let k=0; k<namearr.length; k++){
                        let tokenarr = namearr[k].split('-');
                        caption += tokenarr[1] + ' (' + tokenarr[0] + ') ';
                    }
                    figurelist[name] = {
                        "caption": caption,
                        "figures": []
                    };
                    namelist.push(name);
                }
                if(figurelist[name]["figures"].indexOf(data[0]["figures"][i]["name"]) === -1){
                    figurelist[name]["figures"].push(data[0]["figures"][i]["name"]);
                }
            }
        }
    }
    switch(section_name) {
        case "Summary":
            return data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>);

        case "Region of interest":
            return (
                <Container fluid={true} className='flex-column'>
                    {sample_status.status.cancer_type === "M" && <Iframe url={data[0]["blobs"][0]["data"]["slide_url"]} width="100%" frameBorder="0" height="470px" overflow="auto"/>}
                    {sample_status.status.cancer_type === "O" && <StaticFigure endpoint={getendpoint("scan.png", data)} alt_text="no data available"/>}
                </Container>
            );
        case "Tissue composition":
            return (
                <Container fluid={true} className='flex-column'>
                {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                    <Card key={suffix}>
                        <Card.Header>{suffix}</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>
                )}
                </Container>
            );

        case "Markers":
            const reducer = (acc, cur, idx) => {
                if(idx%3 == 0){
                    acc.push([cur]);
                }
                else{
                    acc[idx%3].push(cur);
                }
            };
            return (
                <Container fluid={true} className='flex-column'>
                {
                    namelist.map((key, id) => {
                        return(
                            <Card key={id}>
                                <Card.Header>
                                    {figurelist[key]["caption"]}
                                </Card.Header>
                                <Card.Body>
                                    <CardGroup>
                                        {
                                            figurelist[key]["figures"].map((suffix) => {
                                                return (
                                                    <Card key={suffix} className="w-50">
                                                    <Card.Header>{"ROI " + suffix.slice(0, suffix.indexOf('_'))}</Card.Header>
                                                    <Card.Body>
                                                        <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                                                    </Card.Body>
                                                    </Card>

                                                );
                                            })
                                        }
                                    </CardGroup>
                                </Card.Body>
                            </Card>
                        );
                    })
                }
                <Card>
                    <Card.Header>
                    Cell interactions per core
                    </Card.Header>
                    <Card.Body>
                        <StaticFigure endpoint={getendpoint("cell_interactions_per_core.png", data)} alt_text="no data available"/>
                    </Card.Body>
                </Card>
                </Container>
            );
        case "Inflammation score":
            return (
                <Container fluid={true} className='flex-column'>
                {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                    <Card key={suffix}>
                        <Card.Header>{suffix}</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>
                )}
                </Container>
            );
        case "Global cell interactions":
            return (
                <Container fluid={true} className='flex-column'>
                {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                    <Card key={suffix}>
                        <Card.Header>{suffix}</Card.Header>
                        <Card.Body>
                            <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        </Card.Body>
                    </Card>
                )}
                </Container>
            );
        case "Cell types and frequencies":
            return (
                <Container fluid={true} className='flex-column'>
                    <CardColumns>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <Card key={suffix} style={{ width: '100%',  }}>
                                <Card.Header>{suffix}</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        )}

                    </CardColumns>
                </Container>
            );
        case "Tumor cells composition":
            return (
                <Container fluid={true} className='flex-column'>
                    <CardDeck>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <Card key={suffix}>
                                <Card.Header>{suffix}</Card.Header>
                                <Card.Body>
                                    <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                                </Card.Body>
                            </Card>
                        )}

                    </CardDeck>    
                </Container>
            );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
