import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Summary from './Summary';
import ScRNA from './ScRNA';
import FastDrugs from './FastDrugs';
import DeepDrugs from './DeepDrugs';
import ScDNA from './ScDNA';
import CyTOF from './CyTOF';
import IMC from './IMC';
import Pathology from './Pathology';
import Proteotype from './Proteotype';
import CfDNA from './CfDNA';
import RNASeq from './RNASeq';
import FMI from './FMI';

function NodePageChooser(props){
    const shared_props = {
        page_name: props.page_name,
        sample_id: props.sample_id,
        sample_status: props.sample_status
    };

    const default_page = (
        <p>No data available</p>
    );
    let page_tag = props.page_name.toLowerCase();
    let page_status = shared_props.sample_status.status[page_tag];
    if(!page_status && page_tag !== 'summary'){
	return default_page;
    }

    if(page_tag !== 'summary'){
	if(page_status[0] !== 'complete'){
           return default_page;
	}
    }
    switch (props.page_name) {
        case "Summary":
                return (
                    <Summary className='flex-column' {...shared_props}  handle_tab={props.handle_tab}/>
                );    
        case "FastDrugs":
            return (
                <FastDrugs className='flex-column' {...shared_props} />
            );
        case "DeepDrugs":
            return (
                <DeepDrugs className='flex-column' {...shared_props} />
            );
        case "ScRNA":
            return (
                <ScRNA className='flex-column' {...shared_props} />
            );
        case "ScDNA":
            return (
                <ScDNA className='flex-column' {...shared_props} />
            );
        case "CyTOF":
            return (
                <CyTOF className='flex-column' {...shared_props} />
            );
        case "IMC":
            return (
                <IMC className='flex-column' {...shared_props} />
            );
        case "Pathology":
            return (
                <Pathology className='flex-column' {...shared_props} />
            );
        case "Proteotype":
            return (
                <Proteotype className='flex-column' {...shared_props} />
            );
        case "CfDNA":
            return (
                <CfDNA className='flex-column' {...shared_props} />
            );
        case "RNA-Seq":
            return (
                <RNASeq className='flex-column' {...shared_props} />
            );
        case "FMI":
            return (
                <FMI className='flex-column' {...shared_props} />
            );
        default:
            return (
                <Container fluid={true} >{props.page_name}: No available data for this sample</Container>
            );
    }
}

/*
function PageHeader(props){
    return (
        <Navbar style={{marginTop:-10}}>
            <Navbar.Brand href="#home">Research use only</Navbar.Brand>
        </Navbar>
    );
}
*/

class Default extends Component {
    render() {
        const {content, sample_id, sample_status, handle_tab} = this.props;

        return (
            <Container fluid={true}>
                <NodePageChooser page_name={content} sample_id={sample_id} sample_status={sample_status} handle_tab={handle_tab}/>
            </Container>
        )
    }
}

export default Default;
