import React from "react";
import update from 'immutability-helper';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 1000
    })
}

export default function DrugChooser({editable, row, updateRow, shared}) {
    // shared drug list from parent so we don't have to duplicate it per row
    const {drugs} = shared;

    if (!editable) {
        return row.drugs.length > 0
            ? <b>{row.drugs.map(x => x.label).join(" + ")}</b>
            : <span className="text-muted font-italic">unspecified</span>;
    }

    const drugsChanged = (x) => {
        // FIXME: also clear out approvals that no longer exist in this array
        updateRow(row._id, {
            drugs: x,
            approval: x
                ? update(row.approval, {
                    $merge: x.reduce((acc, v) => {
                        acc[v.label] = row.approval[v.label] || 'approved';
                        return acc;
                    }, {})
                })
                : {}
        });
    };

    return (
        <div style={{minWidth: '20ex'}}>
            <CreatableSelect
                value={row.drugs} onChange={drugsChanged} options={drugs} hasValue={false}
                placeholder="Select drugs..." isMulti isSearchable
                styles={customStyles}
            />
        </div>
    )
}
