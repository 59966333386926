import React from 'react';
import { Card, CardColumns, CardDeck, CardGroup, Container, Table } from 'react-bootstrap';
import Config from '../../static/config.json';
import SummaryList from '../shared/SummaryList';
import StaticFigure from '../shared/StaticFigure';
import { getendpoint } from '../shared/Utils';
import NodePage from "../shared/NodePage";

function get_content({ page_name, section_name, sample_status, data }){
    if(data[0]["blobs"].length === 0){
        return (<div>No data available</div>);
    }

    switch(section_name) {
        case "Summary":
            return (
                <Container fluid={true} className='flex-column'>
                    {data[0].hasOwnProperty("summary")? (<SummaryList textblob={data[0].summary}/>) : (<SummaryList textblob=""/>)}
                    <CardColumns>
                        {Config[page_name]['Content'][section_name]["figureList-" + sample_status.status.cancer_type].map((suffix)=>
                            <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        )}
                    </CardColumns>
                </Container>
            );
        case "Cell types":
            return(
                <Container fluid={true} className='flex-column'>
                    <p>Total cell number: {data[0]["blobs"][0]["data"]["cell_number"]["val"]? data[0]["blobs"][0]["data"]["cell_number"]["val"]: ""}</p>
                    <CardColumns>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        )}
                    </CardColumns>
                </Container>
            );
        case "Priority Genes":
            return(
                <Container fluid={true} className='flex-column'>
                    <CardDeck>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <StaticFigure key={suffix}  endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        )}
                    </CardDeck>
                </Container>
            );
        case "Genesets":
            return(
                <Container fluid={true} className='flex-column'>
                    <CardDeck>
                        {Config[page_name]['Content'][section_name]["figureList"].map((suffix)=>
                            <StaticFigure key={suffix} endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                        )}
                    </CardDeck>
                </Container>
            );
        case "Drug Gene":
            return(
                <Container fluid={true} className='flex-column'>
                    <CardDeck>
                        <Card>
                            <Card.Header>Differential pathways versus malignant</Card.Header>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint(Config[page_name]['Content'][section_name]["figureList"][0], data)} alt_text="no data available"/>
                            </Card.Body>
                            </Card>
                    </CardDeck>
                    {data[0]["blobs"][0]["data"]["drug_filtered"]["val"] &&
                        <Card>
                            <Card.Header>Drug combination - Filtered drugs</Card.Header>
                            <Card.Body>

                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                            {data[0]["blobs"][0]["data"]["drug_filtered"]["val"]["labels"].map((each, id) => (
                                                <th key={id}>{each}</th>
                                            ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data[0]["blobs"][0]["data"]["drug_filtered"]["val"]["vals"].map((each, id_row) => (
                                                <tr key={id_row}>
                                                    {each.map((cell, id_cell) => (
                                                        <td key={id_cell}>{cell}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                            </Card.Body>
                        </Card>
                    }
                    <CardGroup>
                        <Card>
                            <Card.Header>Differential pathways versus malignant</Card.Header>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint(Config[page_name]['Content'][section_name]["figureList"][1], data)} alt_text="no data available"/>
                            </Card.Body>

                        </Card>
                        <Card>
                            <Card.Header>Differential pathways versus normal</Card.Header>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint(Config[page_name]['Content'][section_name]["figureList"][2], data)} alt_text="no data available"/>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Container>

            );
        case "Individual Gene Expression":
            return(
                <Container fluid={true} className='flex-column'>
                    {Config[page_name]['Content'][section_name]["figureList-" + sample_status.status.cancer_type].map((suffix)=>
                        <Card key={suffix}>
                            <Card.Header>{suffix}</Card.Header>
                            <Card.Body>
                                <StaticFigure endpoint={getendpoint(suffix, data)} alt_text="no data available"/>
                            </Card.Body>
                        </Card>
                    )}
                </Container>
            );
        case "Cell types and proportions":
            if (!data[0]["blobs"][0]["data"]["cell_type_table"]["val"]){
                return <div />
            }
            return(
                <Container fluid={true} className='flex-column'>
                    <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                        {

                            data[0]["blobs"][0]["data"]["cell_type_table"]["val"]["tableHeaders"].map((each, id) => {
                                return (
                                    <th key={id}>{each}</th>
                                );
                            })
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data[0]["blobs"][0]["data"]["cell_type_table"]["val"]["tablesVals"].map((row, id_row) =>{
                                return (
                                <tr key={id_row}>{row.map((cell, id_cell)=>{
                                    return (
                                        <td key={id_cell}>{cell}</td>
                                    );
                                })}</tr>
                                );
                            })
                        }
                    </tbody>
                    </Table>
                </Container>
            );
        default:
            return <div />;
    }
}

export default ({ page_name, sample_id, sample_status }) => (
    <NodePage page_name={page_name} sample_id={sample_id} sample_status={sample_status} content_accessor={get_content} />
)
