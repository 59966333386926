import React from 'react';
import FigureFrame from './FigureFrame';

const StaticFigure = ({endpoint, alt_text}) => {
    return (
        <FigureFrame endpoint={endpoint} alt_text={alt_text}/>
    );
};

export default StaticFigure;
