import React, { Component } from 'react';
import { Card, Col, Container, ListGroup, Row, Tab } from 'react-bootstrap';
import Config from '../static/config.json';
import Default from './pages/Default';
import { DataProviderFunc } from './shared/DataProvider';

function SidebarIcon(props){
    let icon;
    switch (props.category) {
        case "response testing":
            icon = <i className="fas fa-capsules fa-lg float-right"/>;
            break;
        case "single cells":
            icon = <i className="fas fa-spinner fa-lg float-right"/>;
            break;
        case "imaging/tissue":
            icon = <i className="fab fa-instagram fa-lg float-right"/>;
            break;
        case "bulk":
            icon = <i className="fas fa-flask fa-lg float-right"/>;
            break;
        default:
            icon = <i/>;
            break;
    }

    return icon;

}

const scrollToTop = () => {
    // just scrolls to the top of the viewport when changing sections
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
};

function SidebarItem(props){
    if(! Config.hasOwnProperty(props.page_name)){
        alert(props.page_name);
    }

    // let sections = Config[props.page_name]['Sections'];
    /*
    <NavDropdown title={props.page_name} id={'nav-down'+props.page_name}>
        {sections.map((each)=>
            <NavDropdown.Item>{each}</NavDropdown.Item>
        )}
    </NavDropdown>
     */

    const getVariant = function(category){
        let variant = 'primary';
        if (category !== 'summary'){
            variant = 'secondary';
        }
        return variant;
    };

    return(
        <ListGroup.Item
            disabled={props.disabled}
            href={props.page_name}
            variant={getVariant(Config[props.page_name]['Category'])}
            onClick={scrollToTop}
        >
            {props.page_name} <SidebarIcon category={Config[props.page_name]['Category']}/>
        </ListGroup.Item>
    );
}

function Sidebar(props){
    const node_list = [
        'Summary',
        ...props.submissions.filter(x => x.status == "C" && x.availablePreTB === true).map(x => x.node_name)
    ];

    return (
        <ListGroup className='flex-column'>
            { props.page_list.map((item) => <SidebarItem key={item} page_name={item} disabled={!node_list.includes(item)}/>) }
        </ListGroup>
    );
}

function Legend(props){
    let style = {
        marginTop: 10
    };
    return (
        <Card style={style}>
            <Card.Body>
                <h6 className="card-text">Legend</h6>
                <ul className="list-unstyled">
                    <li><i className="fas fa-capsules fa-lg mt-3" /> response testing</li>
                    <li><i className="fas fa-spinner fa-lg mt-3" /> single cells</li>
                    <li><i className="fab fa-instagram fa-lg mt-3" /> imaging/tissue</li>
                    <li><i className="fas fa-flask fa-lg mt-3" /> bulk</li>
                </ul>
            </Card.Body>
        </Card>
    );
}

// allows all elements under Content to access patient_id and sample_id via
// useContext(PatientSampleContext) in their render function
export const PatientSampleContext = React.createContext({ patient_id: null, sample_id: null });

class Content extends Component {
    style = {
        'container_style': {
            marginTop: 15
        },
        'sidebar_style': {
            position: 'fixed'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
          key: Config.TABS[0]
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(key){
        console.log('selected' + key);
        this.setState({ key: key });
    };

    render() {
        const { patient_id, sample_id } = this.props;


        return (
            <PatientSampleContext.Provider value={{patient_id, sample_id}}>
                <DataProviderFunc
                    endpoint={"/submissions/?sample__id=" + sample_id}
                    render={(data, { sampleStatus }) =>
                        <Container className="position-relative" style={this.style['container_style']} fluid={true}>
                            <Tab.Container activeKey={this.state.key} onSelect={this.handleSelect}>
                                <Row className="h-100">
                                    <Col sm={2}>
                                        <div className="sticky-top tpr-sidebar pt-2">
                                            <Sidebar page_list={Config.TABS} submissions={data}/>
                                            <Legend />
                                        </div>
                                    </Col>

                                    <Col sm={10}>
                                        <Tab.Content>
                                            {
                                                Config.TABS.map((item)=>
                                                    <Tab.Pane eventKey={item} key={item}>
                                                        <Default content={item} sample_id={sample_id} sample_status={sampleStatus} handle_tab = {this.handleSelect} />
                                                    </Tab.Pane>
                                                )
                                            }
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    }
                />
            </PatientSampleContext.Provider>
        )
    }
}

export default Content
