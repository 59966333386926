import React from 'react';
import { Button, Nav, Navbar } from "react-bootstrap";
import { withKeycloak } from "react-keycloak";
import tp_logo from '../../static/images/tp_logo.png';
import { Link } from "react-router-dom";
import { oldStaticUrl } from '../../environment';

const AuthBlurb = ({ keycloak }) => {
    if (keycloak.authenticated) {
        return (
            <>
                <Navbar.Text>logged in as <Link to='/user-info'>{keycloak.idTokenParsed.name}</Link></Navbar.Text>
                <Nav.Link onClick={(e) => { keycloak.logout(); e.preventDefault(); }}>log out</Nav.Link>
            </>
        );
    }
    else {
        return (
            <Nav.Link onClick={(e) => { keycloak.login(); e.preventDefault(); }}>log in</Nav.Link>
        );
    }
};

const PageHeader = ({ keycloak, patient_id, sample_id }) => {
    return (
        <Navbar bg="dark" variant="dark" expand='lg'>
            <Navbar.Brand as={Link} to="/">
                <img src={tp_logo} style={{height: 40}} data-toggle="tooltip" alt="TPR: Home" />
            </Navbar.Brand>

            {
                patient_id && (
                    <Navbar.Brand href="">
                        <Button variant="outline-primary">{patient_id}</Button>
                    </Navbar.Brand>
                )
            }

            {
                sample_id && (
                    <Navbar.Brand href="">
                        <Button variant="outline-primary">{sample_id}</Button>
                    </Navbar.Brand>
                )
            }
            {
                sample_id && (
                    <Navbar.Brand href={oldStaticUrl + '/' + sample_id + '/report.html'}>
                        <Button variant="warning">Pre-TB Version</Button>
                    </Navbar.Brand>
                )
            }
            {
                !sample_id && (
                    <Navbar.Brand href={'/'}>
                        <Button variant="outline-warning">Drug Info Package</Button>
                    </Navbar.Brand>
                )
            }
            <Navbar.Toggle />

            <Navbar.Collapse bg='dark' variant="dark">
                <Nav className="mr-auto">
                {/*main links here*/}
                </Nav>

                <AuthBlurb keycloak={keycloak} />
            </Navbar.Collapse>
        </Navbar>
    );
};

export default withKeycloak(PageHeader);
