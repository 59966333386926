import React, { createContext } from 'react';
import { DataProviderFunc } from './shared/DataProvider';
import Content from './Content';

export const StatusContext = createContext()

const Report = (props) => {
        const {patient_id, sample_id} = props;
        //set context of sample status

        return (
            <DataProviderFunc
            endpoint={"/samples/?id=" + sample_id}
            render={data =>
                <StatusContext.Provider value = {data[0]}>
                    <Content patient_id={patient_id} sample_id={sample_id}/>
                </StatusContext.Provider>
            }
            />



        );

}

export default Report;
