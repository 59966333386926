
export function getendpoint(suffix, data){
    const found = data[0].figures.find(x => x.name === suffix);
    return found ? found.figure : null;
}

export function get_val(obj, key) {
    return key.split(".").reduce(function(o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}